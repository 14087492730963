import {
  map, find, omit, isEqual,
  cloneDeep, forEach, reduce, filter,
  has,
} from 'lodash';
import {
  checkIfRequiredFieldsEntered,
} from './player-setup-mapper';
import marketMapper from './market-mapper';

export const MARKET_TIER_MIN_VALUE = 1;
export const MARKET_TIER_MAX_VALUE = 4;

export const mapPlayerProps = (playerPropsData, squadData, event) => {
  if (!playerPropsData || !squadData || !event) return [];

  const { homeTeam, awayTeam } = playerPropsData;
  const { homeSquad, awaySquad } = squadData;
  const { isUsaView, competitors } = event;

  const teamASquadData = isUsaView ? awaySquad : homeSquad;
  const teamBSquadData = isUsaView ? homeSquad : awaySquad;

  const teamASquad = map(teamASquadData.squad, (squad) => squad.player);
  const teamBSquad = map(teamBSquadData.squad, (squad) => squad.player);

  let tempTeamA = isUsaView ? awayTeam : homeTeam;
  let tempTeamB = isUsaView ? homeTeam : awayTeam;

  tempTeamA = map(tempTeamA?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
    playerPositionIndex: player?.playerPositionIndex || 1,
    // Baseball params
    isPitcher: player.isPitcher,
    isHitter: player.isHitter,
    disabled: player?.disabled,
  }));

  tempTeamB = map(tempTeamB?.playersParams, (player) => ({
    ...player?.playerParams,
    lineup: player?.lineup,
    voided: player?.voided,
    marketCodeDetails: player?.marketCodeDetails,
    playerPositionIndex: player?.playerPositionIndex || 1,
    // Baseball params
    isPitcher: player.isPitcher,
    isHitter: player.isHitter,
    disabled: player?.disabled,
  }));

  const data = {
    teamA: {
      teamId: teamASquadData.teamId,
      name: find(competitors, { teamId: teamASquadData.teamId }).name,
    },
    teamB: {
      teamId: teamBSquadData.teamId,
      name: find(competitors, { teamId: teamBSquadData.teamId }).name,
    },
  };
  data.teamA.playerProps = map(tempTeamA, (player) => ({
    ...player,
    playerName: find(teamASquad, { playerId: player.playerId })?.personalInfo
      ?.name,
    marketCodeDetails: map(player.marketCodeDetails, (market) => ({
      ...market,
      marketName: marketMapper.playersMarketName(market.marketCode),
    })),
    isSuspended:
    player.marketCodeDetails.length
    && !filter(player.marketCodeDetails, (market) => !market.suspended)?.length,
    playerLocked: true,
  }));
  data.teamB.playerProps = map(tempTeamB, (player) => ({
    ...player,
    playerName: find(teamBSquad, { playerId: player.playerId })?.personalInfo
      ?.name,
    marketCodeDetails: map(player.marketCodeDetails, (market) => ({
      ...market,
      marketName: marketMapper.playersMarketName(market.marketCode),
    })),
    isSuspended:
    player.marketCodeDetails.length
    && !filter(player.marketCodeDetails, (market) => !market.suspended)?.length,
    playerLocked: true,
  }));
  return data;
};

export const mapTradingPlayerPropsPublishPayloadByTeam = (teamData) => map(teamData, (player) => ({
  playerParams: { ...omit(player, ['marketCodeDetails', 'lineup', 'voided', 'disabled', 'isHitter', 'isPitcher', 'playerPositionIndex']) },
  marketCodeDetails: player.marketCodeDetails,
  lineup: player.lineup,
  voided: player.voided,
  playerPositionIndex: player.playerPositionIndex,
  // Baseball params
  disabled: player.disabled,
  isHitter: player.isHitter,
  isPitcher: player.isPitcher,
}));

export const mapTradingPlayerPropsPublishPayload = (tradingPlayerProps, tradingEvent, selectedParamSource = 'MANUAL') => {
  const payload = cloneDeep(tradingPlayerProps);
  const isUSAView = has(tradingEvent, 'isUSAView') ? tradingEvent.isUSAView : tradingEvent.isUsaView;
  const homeTeam = isUSAView ? payload.teamB : payload.teamA;
  const awayTeam = isUSAView ? payload.teamA : payload.teamB;

  return {
    homeTeam: {
      playersParams: mapTradingPlayerPropsPublishPayloadByTeam(homeTeam.playerProps),
    },
    awayTeam: {
      playersParams: mapTradingPlayerPropsPublishPayloadByTeam(awayTeam.playerProps),
    },
    selectedParamSource,
  };
};

export const mapTradingPlayerPropsPricesSimulatePayload = (tradingPlayerProps, tradingEvent) => {
  const payload = cloneDeep(tradingPlayerProps);
  const homeTeam = tradingEvent.isUsaView ? payload.teamB : payload.teamA;
  const awayTeam = tradingEvent.isUsaView ? payload.teamA : payload.teamB;
  homeTeam.players = map(homeTeam.playerProps, (player) => omit({
    ...player,
    marketCodes: map(player.marketCodeDetails, (market) => market.marketCode),
  }, ['marketCodeDetails']));
  awayTeam.players = map(awayTeam.playerProps, (player) => omit({
    ...player,
    marketCodes: map(player.marketCodeDetails, (market) => market.marketCode),
  }, ['marketCodeDetails']));

  return {
    homeTeam: omit(homeTeam, ['name', 'teamId', 'playerProps']),
    awayTeam: omit(awayTeam, ['name', 'teamId', 'playerProps']),
  };
};

export const mapNewMarketPrices = (players) => reduce(
  players,
  (allPlayers, player) => {
    const marketsPerPlayer = [];
    forEach(player.markets, (market) => {
      marketsPerPlayer.push({
        selections: market?.selections,
        marketSummary: market?.marketSummary,
        marketType: market?.marketType,
      });
    });
    return [
      ...allPlayers,
      ...marketsPerPlayer,
    ];
  },
  [],
);

export const mergeNewMarketPrices = (allMarkets, updatedMarkets) => reduce(
  allMarkets,
  (markets, existingMarket) => {
    let updatedMarket = find(updatedMarkets, (market) => {
      const existingMarketParams = existingMarket?.marketType?.params;
      const updatedMarketParams = market?.marketType?.params;
      return (
        existingMarketParams.LINE === updatedMarketParams.LINE
        && existingMarketParams.SELECTIONS === updatedMarketParams.SELECTIONS
        && existingMarketParams.PLAYER_INDEX === updatedMarketParams.PLAYER_INDEX
      );
    });

    updatedMarket = updatedMarket ? { ...existingMarket, ...updatedMarket } : existingMarket;

    return [
      ...markets,
      ...[updatedMarket],
    ];
  },
  [],
);

const omitMarketsFieldFromTeamPlayer = (data) => {
  const { teamA, teamB } = cloneDeep(data);
  teamA.playerProps = map(teamA.playerProps, (player) => omit(player, ['marketCodeDetails']));
  teamB.playerProps = map(teamB.playerProps, (player) => omit(player, ['marketCodeDetails']));

  return {
    teamA,
    teamB,
  };
};

export const isTradingPlayerPropsStateChanged = (lastState, newState) => {
  const lastStateWithMarketsOmited = omitMarketsFieldFromTeamPlayer(lastState);
  const newStateWithMarketsOmited = omitMarketsFieldFromTeamPlayer(newState);
  return !isEqual(lastStateWithMarketsOmited, newStateWithMarketsOmited);
};
export const checkIsPublishBtnEnabled = (lastState, newState) => !isEqual(lastState, newState);

export const checkIsTradingTeamPropsModelValid = (model, sportId) => {
  const { teamA, teamB } = model;

  if (!checkIfRequiredFieldsEntered(teamA.playerProps, sportId)
  || !checkIfRequiredFieldsEntered(teamB.playerProps, sportId)) {
    return false;
  }
  return true;
};

export const mapSquadData = (teamA, teamB) => [
  ...map(teamA.squad, (info) => ({
    ...info.player,
    teamId: teamA.teamId,
  })),
  ...map(teamB.squad, (info) => ({
    ...info.player,
    teamId: teamB.teamId,
  })),
];

// Here we can add player props when we get the info about their range
export const checkIsTradingPropsModelValid = (model, sportId) => checkIsTradingTeamPropsModelValid(model, sportId);

export const footballParameters = () => ([
  {
    param: 'goalTouchdown',
    label: 'Goals',
    order: 1,
  },
  {
    param: 'redTouchdown',
    label: 'Red zone TD',
    order: 2,
  },
  {
    param: 'fieldTouchdown',
    label: 'Field TD',
    order: 3,
  },
  {
    param: 'rushLoad',
    label: 'Rush',
    order: 4,
  },
  {
    param: 'qbLoad',
    label: 'QB',
    order: 5,
  },
  {
    param: 'longReceptionLoad',
    label: 'Long Rec',
    order: 6,
  },
  {
    param: 'shortReceptionLoad',
    label: 'Short Rec',
    order: 7,
  },
]);

export const basketballParameters = () => ([
  {
    param: 'time',
    label: 'Time',
    order: 1,
  },
  {
    param: 'points',
    label: 'Points',
    order: 2,
  },
  {
    param: 'threes',
    label: 'Threes',
    order: 3,
  },
  {
    param: 'rebounds',
    label: 'Rebounds',
    order: 4,
  },
  {
    param: 'assists',
    label: 'Assists',
    order: 5,
  },
  {
    param: 'quarter4UsageBoost.offensiveReboundPutbackBoost',
    label: 'ORB P Boost',
    order: 6,
  },
  {
    param: 'steals',
    label: 'Steals',
    order: 7,
  },
  {
    param: 'blocks',
    label: 'Blocks',
    order: 8,
  },
]);

export const hockeyParameters = () => ([
  {
    param: 'goals',
    label: 'Goals',
    order: 1,
  },
  {
    param: 'assists',
    label: 'Assists',
    order: 2,
  },
]);

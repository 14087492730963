import {
  orderBy, sortBy, filter, isArray,
  countBy, find, each, map, includes,
} from 'lodash';

import sportIds from '@/services/helpers/sports';

const {
  FOOTBALL_ID,
  BASKETBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

const HUDDLE_MARKET_SOURCE = 'INTERNAL_AGGREGATION';

const EXCLUSION_MARKET_TYPES = {
  POINT_MARGIN: 'POINT_MARGIN',
  HIGHEST_SCORING_HALF: 'HIGHEST_SCORING_HALF',
  HIGHEST_SCORING_PERIOD: 'HIGHEST_SCORING_PERIOD',
  POINT_RACE_TO_X: 'POINT_RACE_TO_X',
  HALF_POINT_RACE_TO_X: 'HALF_POINT_RACE_TO_X',
  PERIOD_POINT_RACE_TO_X: 'PERIOD_POINT_RACE_TO_X',
  HALF_POINT_ODD_EVEN: 'HALF_POINT_ODD_EVEN',
  POINT_ODD_EVEN_INC_OT: 'POINT_ODD_EVEN_INC_OT',
  PERIOD_POINT_ODD_EVEN: 'PERIOD_POINT_ODD_EVEN',
  POINT_ODD_EVEN: 'POINT_ODD_EVEN',
  TEAM_HALF_POINT_ODD_EVEN: 'TEAM_HALF_POINT_ODD_EVEN',
  TEAM_PERIOD_POINT_ODD_EVEN: 'TEAM_PERIOD_POINT_ODD_EVEN',
  TEAM_POINT_ODD_EVEN: 'TEAM_POINT_ODD_EVEN',
  TO_GO_TO_OVERTIME: 'TO_GO_TO_OVERTIME',
  POINT_ASIAN_HANDICAP: 'POINT_ASIAN_HANDICAP',
  PLAYER_LAST_TOUCHDOWN_SCORER: 'PLAYER_LAST_TOUCHDOWN_SCORER',
  PLAYER_ANYTIME_TOUCHDOWN_SCORER: 'PLAYER_ANYTIME_TOUCHDOWN_SCORER',
  PLAYER_NEXT_TOUCHDOWN_SCORER: 'PLAYER_NEXT_TOUCHDOWN_SCORER',
  PLAYER_PASSING_ATTEMPTS_OVER_UNDER: 'PLAYER_PASSING_ATTEMPTS_OVER_UNDER',
  PLAYER_PASSING_COMPLETIONS_OVER_UNDER: 'PLAYER_PASSING_COMPLETIONS_OVER_UNDER',
  PLAYER_PASSING_YARDS_OVER_UNDER: 'PLAYER_PASSING_YARDS_OVER_UNDER',
  PLAYER_RECEIVING_YARDS_OVER_UNDER: 'PLAYER_RECEIVING_YARDS_OVER_UNDER',
  PLAYER_RECEPTIONS_OVER_UNDER: 'PLAYER_RECEPTIONS_OVER_UNDER',
  PLAYER_RUSHING_YARDS_OVER_UNDER: 'PLAYER_RUSHING_YARDS_OVER_UNDER',
  PLAYER_TOUCHDOWNS_OVER_UNDER: 'PLAYER_TOUCHDOWNS_OVER_UNDER',

  HALF_CORRECT_SCORE: 'HALF_CORRECT_SCORE',
  HANDICAP_AND_OVER_UNDER: 'HANDICAP_AND_OVER_UNDER',
  MATCH_RESULT_AND_POINTS_OVER_UNDER: 'MATCH_RESULT_AND_POINTS_OVER_UNDER',
  NEXT_BASKET_EXACT: 'NEXT_BASKET_EXACT',
  NEXT_BASKET_TYPE: 'NEXT_BASKET_TYPE',
  NEXT_TWO_POINTER: 'NEXT_TWO_POINTER',
  NEXT_THREE_POINTER: 'NEXT_THREE_POINTER',
  PLAYER_POINT_OVER_UNDER: 'PLAYER_POINT_OVER_UNDER',
  PLAYER_POINT_BANDS: 'PLAYER_POINT_BANDS',
  PLAYER_REBOUND_OVER_UNDER: 'PLAYER_REBOUND_OVER_UNDER',
  PLAYER_ASSIST_OVER_UNDER: 'PLAYER_ASSIST_OVER_UNDER',
  PLAYER_THREE_POINTER_OVER_UNDER: 'PLAYER_THREE_POINTER_OVER_UNDER',
  ANYTIME_GOALSCORER: 'ANYTIME_GOALSCORER',
  ANY_QUARTER_TO_BE_SCORELESS: 'ANY_QUARTER_TO_BE_SCORELESS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE',
  CORRECT_SCORE: 'CORRECT_SCORE',
  CORRECT_SCORE_EXC_OT: 'CORRECT_SCORE_EXC_OT',
  DEFENSIVE_OR_SPECIAL_TOUCHDOWN: 'DEFENSIVE_OR_SPECIAL_TOUCHDOWN',
  DRIVE_CROSSES_YARD_LINE: 'DRIVE_CROSSES_YARD_LINE',
  DRIVE_OUTCOME: 'DRIVE_OUTCOME',
  DRIVE_OUTCOME_GROUPED: 'DRIVE_OUTCOME_GROUPED',
  EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY: 'EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY',
  EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW: 'EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW',
  EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW: 'EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW',
  EITHER_TEAM_WIN_BY_THREE_OR_SEVEN: 'EITHER_TEAM_WIN_BY_THREE_OR_SEVEN',
  FIELD_GOAL_OVER_UNDER: 'FIELD_GOAL_OVER_UNDER',
  FIRST_DOWN_OVER_UNDER: 'FIRST_DOWN_OVER_UNDER',
  FIRST_DOWN_RESULT: 'FIRST_DOWN_RESULT',
  FIRST_PITCHER_TOTAL_STRIKEOUTS: 'FIRST_PITCHER_TOTAL_STRIKEOUTS',
  FIRST_SCORE_IS_A_TOUCHDOWN: 'FIRST_SCORE_IS_A_TOUCHDOWN',
  FOURTH_QUARTER_LEAD_CHANGE: 'FOURTH_QUARTER_LEAD_CHANGE',
  GOAL_IN_TIME_BIN: 'GOAL_IN_TIME_BIN',
  GOAL_MARGIN: 'GOAL_MARGIN',
  GOAL_MARGIN_EXC_OT: 'GOAL_MARGIN_EXC_OT',
  GOAL_ODD_EVEN_EXC_OT: 'GOAL_ODD_EVEN_EXC_OT',
  GOAL_RACE_TO_X: 'GOAL_RACE_TO_X',
  HALF_TIME_FULL_TIME_TIES_LOSE: 'HALF_TIME_FULL_TIME_TIES_LOSE',
  LAST_GOAL: 'LAST_GOAL',
  LAST_GOALSCORER: 'LAST_GOALSCORER',
  LAST_SCORE_IS_A_TOUCHDOWN: 'LAST_SCORE_IS_A_TOUCHDOWN',
  LONGEST_DRIVE_YARDS_OVER_UNDER: 'LONGEST_DRIVE_YARDS_OVER_UNDER',
  LONGEST_FIELD_GOAL_YARDS_OVER_UNDER: 'LONGEST_FIELD_GOAL_YARDS_OVER_UNDER',
  NEXT_CONVERSION: 'NEXT_CONVERSION',
  NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL: 'NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL',
  NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL: 'NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL',
  NEXT_FIELD_GOAL_MADE: 'NEXT_FIELD_GOAL_MADE',
  NEXT_GOAL: 'NEXT_GOAL',
  NEXT_GOALSCORER: 'NEXT_GOALSCORER',
  NEXT_KICKOFF_TOUCHBACK: 'NEXT_KICKOFF_TOUCHBACK',
  NEXT_PLAY_IS_A_FIRST_DOWN: 'NEXT_PLAY_IS_A_FIRST_DOWN',
  NEXT_PLAY_TOUCHDOWN: 'NEXT_PLAY_TOUCHDOWN',
  NEXT_PLAY_TYPE: 'NEXT_PLAY_TYPE',
  NEXT_POSSESSION_OUTCOME: 'NEXT_POSSESSION_OUTCOME',
  NEXT_POSSESSION_OUTCOME_EXACT: 'NEXT_POSSESSION_OUTCOME_EXACT',
  NEXT_PUNT_FAIR_CATCH: 'NEXT_PUNT_FAIR_CATCH',
  PERIOD_CORRECT_SCORE: 'PERIOD_CORRECT_SCORE',
  PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES: 'PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES',
  PERIOD_PLAYER_POINT_BANDS: 'PERIOD_PLAYER_POINT_BANDS',
  PERIOD_PLAYER_POINT_OVER_UNDER: 'PERIOD_PLAYER_POINT_OVER_UNDER',
  PERIOD_PLAYER_THREE_POINTER_OVER_UNDER: 'PERIOD_PLAYER_THREE_POINTER_OVER_UNDER',
  PERIOD_POINT_HANDICAP_EXC_OT: 'PERIOD_POINT_HANDICAP_EXC_OT',
  PERIOD_POINT_OVER_UNDER_EXC_OT: 'PERIOD_POINT_OVER_UNDER_EXC_OT',
  PERIOD_RESULT_EXC_OT: 'PERIOD_RESULT_EXC_OT',
  PERIOD_RESULT_FULL_TIME: 'PERIOD_RESULT_FULL_TIME',
  PERIOD_RUN_HANDICAP: 'PERIOD_RUN_HANDICAP',
  PERIOD_RUN_OVER_UNDER: 'PERIOD_RUN_OVER_UNDER',
  PITCH_OUTCOME: 'PITCH_OUTCOME',
  PITCH_SPEED_BANDED: 'PITCH_SPEED_BANDED',
  PITCH_SPEED_OVER_UNDER: 'PITCH_SPEED_OVER_UNDER',
  PLATE_APPEARANCE_PITCHES_OVER_UNDER: 'PLATE_APPEARANCE_PITCHES_OVER_UNDER',
  PLATE_APPEARANCE_RESULT_EXACT: 'PLATE_APPEARANCE_RESULT_EXACT',
  PLATE_APPEARANCE_RESULT_GROUPED: 'PLATE_APPEARANCE_RESULT_GROUPED',
  PLATE_APPEARANCE_RESULT_ON_BASE: 'PLATE_APPEARANCE_RESULT_ON_BASE',
  PLAYER_BASES_OVER_UNDER: 'PLAYER_BASES_OVER_UNDER',
  PLAYER_HOME_RUN: 'PLAYER_HOME_RUN',
  PLAYER_ON_BASE: 'PLAYER_ON_BASE',
  PLAYER_ON_BASE_TWO_PLUS: 'PLAYER_ON_BASE_TWO_PLUS',
  PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER: 'PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER',
  PLAYER_RBI: 'PLAYER_RBI',
  PLAYER_RUN_OVER_UNDER: 'PLAYER_RUN_OVER_UNDER',
  PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER: 'PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER',
  PLAYER_SHOTS_OVER_UNDER: 'PLAYER_SHOTS_OVER_UNDER',
  PLAYER_STOLEN_BASE: 'PLAYER_STOLEN_BASE',
  PLAYER_STRIKEOUT_OVER_UNDER: 'PLAYER_STRIKEOUT_OVER_UNDER',
  PLAYER_TOUCHDOWN_X_OR_MORE: 'PLAYER_TOUCHDOWN_X_OR_MORE',
  PUNT_OVER_UNDER: 'PUNT_OVER_UNDER',
  PUNT_YARDS_OVER_UNDER: 'PUNT_YARDS_OVER_UNDER',
  RESULT_AFTER: 'RESULT_AFTER',
  TWO_WAY_RESULT_AFTER: 'TWO_WAY_RESULT_AFTER',
  RUN_HANDICAP: 'RUN_HANDICAP',
  RUN_HANDICAP_AFTER: 'RUN_HANDICAP_AFTER',
  RUN_MARGIN: 'RUN_MARGIN',
  RUN_OVER_UNDER: 'RUN_OVER_UNDER',
  RUN_OVER_UNDER_AFTER: 'RUN_OVER_UNDER_AFTER',
  SAFETY_SCORED: 'SAFETY_SCORED',
  SECOND_HALF_LEAD_CHANGE: 'SECOND_HALF_LEAD_CHANGE',
  STRIKEOUT_OVER_UNDER: 'STRIKEOUT_OVER_UNDER',
  SUCCESSFUL_TWO_POINT_CONVERSION: 'SUCCESSFUL_TWO_POINT_CONVERSION',
  TEAM_FIRST_TO_SCORE: 'TEAM_FIRST_TO_SCORE',
  TEAM_LAST_TO_SCORE: 'TEAM_LAST_TO_SCORE',
  TEAM_NEXT_BASKET_TYPE: 'TEAM_NEXT_BASKET_TYPE',
  TEAM_TO_SCORE_NEXT_BASKET: 'TEAM_TO_SCORE_NEXT_BASKET',
  TEAM_NEXT_TO_SCORE: 'TEAM_NEXT_TO_SCORE',
  TEAM_OVER_UNDER: 'TEAM_OVER_UNDER',
  TEAM_PERIOD_BALLS_IN_PLAY: 'TEAM_PERIOD_BALLS_IN_PLAY',
  TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER: 'TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER',
  TEAM_PERIOD_BATTERS_TO_PLATE: 'TEAM_PERIOD_BATTERS_TO_PLATE',
  TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER: 'TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER',
  TEAM_PERIOD_HITS: 'TEAM_PERIOD_HITS',
  TEAM_PERIOD_HITS_OVER_UNDER: 'TEAM_PERIOD_HITS_OVER_UNDER',
  TEAM_PERIOD_HOME_RUN: 'TEAM_PERIOD_HOME_RUN',
  TEAM_PERIOD_PITCHES_BANDED: 'TEAM_PERIOD_PITCHES_BANDED',
  TEAM_PERIOD_PITCHES_OVER_UNDER: 'TEAM_PERIOD_PITCHES_OVER_UNDER',
  TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT: 'TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT',
  TEAM_PERIOD_RUN_SCORED: 'TEAM_PERIOD_RUN_SCORED',
  TEAM_PERIOD_RUN_OVER_UNDER: 'TEAM_PERIOD_RUN_OVER_UNDER',
  TEAM_PERIOD_STRIKEOUT_OVER_UNDER: 'TEAM_PERIOD_STRIKEOUT_OVER_UNDER',
  TEAM_PERIOD_THREE_BATTERS_REACH_BASE: 'TEAM_PERIOD_THREE_BATTERS_REACH_BASE',
  TEAM_PERIOD_THREE_BATTERS_STRIKEOUT: 'TEAM_PERIOD_THREE_BATTERS_STRIKEOUT',
  TEAM_PERIOD_TO_SCORE: 'TEAM_PERIOD_TO_SCORE',
  TEAM_RUN_OVER_UNDER: 'TEAM_RUN_OVER_UNDER',
  TEAM_RUN_OVER_UNDER_AFTER: 'TEAM_RUN_OVER_UNDER_AFTER',
  TEAM_SCORE_IN_EACH_PERIOD: 'TEAM_SCORE_IN_EACH_PERIOD',
  TEAM_STACK_ATTACK_OVER_UNDER: 'TEAM_STACK_ATTACK_OVER_UNDER',
  TEAM_STRIKEOUT_OVER_UNDER: 'TEAM_STRIKEOUT_OVER_UNDER',
  TEAM_TO_SCORE_FIRST_WINS_MATCH: 'TEAM_TO_SCORE_FIRST_WINS_MATCH',
  TEAM_TO_SCORE_IN_OWN_DRIVE: 'TEAM_TO_SCORE_IN_OWN_DRIVE',
  TEAM_TO_SCORE_LAST_WINS_MATCH: 'TEAM_TO_SCORE_LAST_WINS_MATCH',
  TIE_AFTER_NIL_NIL: 'TIE_AFTER_NIL_NIL',
  TIME_OF_FIRST_SCORE: 'TIME_OF_FIRST_SCORE',
  TOUCHDOWN_IN_TIME_BIN: 'TOUCHDOWN_IN_TIME_BIN',
  TOUCHDOWN_OVER_UNDER: 'TOUCHDOWN_OVER_UNDER',
  TO_GO_THREE_AND_OUT: 'TO_GO_THREE_AND_OUT',
  TURNOVER_OVER_UNDER: 'TURNOVER_OVER_UNDER',
  WIN_TO_NIL: 'WIN_TO_NIL',
  LAST_POINT: 'LAST_POINT',
  FIRST_SCORER_WINS_MATCH: 'FIRST_SCORER_WINS_MATCH',
  MOST_POINTS_IN_SECOND_QUARTER: 'MOST_POINTS_IN_SECOND_QUARTER',
  FIELD_GOAL_ODD_EVEN: 'FIELD_GOAL_ODD_EVEN',
  THREE_WAY_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_FIELD_GOAL_RESULT: 'THREE_WAY_FIELD_GOAL_RESULT',
  TWO_WAY_FIELD_GOAL_RESULT: 'TWO_WAY_FIELD_GOAL_RESULT',
  HALF_FIELD_GOAL_ODD_EVEN: 'HALF_FIELD_GOAL_ODD_EVEN',
  HALF_FIELD_GOAL_OVER_UNDER: 'HALF_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER',
  HALF_FIELD_GOAL_RESULT: 'HALF_FIELD_GOAL_RESULT',
  THREE_WAY_HALF_FIELD_GOAL_RESULT: 'THREE_WAY_HALF_FIELD_GOAL_RESULT',
  PERIOD_FIELD_GOAL_ODD_EVEN: 'PERIOD_FIELD_GOAL_ODD_EVEN',
  PERIOD_FIELD_GOAL_OVER_UNDER: 'PERIOD_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER',
  PERIOD_FIELD_GOAL_RESULT: 'PERIOD_FIELD_GOAL_RESULT',
  THREE_WAY_PERIOD_FIELD_GOAL_RESULT: 'THREE_WAY_PERIOD_FIELD_GOAL_RESULT',
  TEAM_HALF_FIELD_GOAL_OVER_UNDER: 'TEAM_HALF_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER',
  TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: 'TEAM_PERIOD_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER',
  POINT_HANDICAP_EXC_OT: 'POINT_HANDICAP_EXC_OT',
  POINT_OVER_UNDER_EXC_OT: 'POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_TEAM_POINT_OVER_UNDER: 'THREE_WAY_TEAM_POINT_OVER_UNDER',
  HALF_POINT_HANDICAP_EXC_OT: 'HALF_POINT_HANDICAP_EXC_OT',
  THREE_WAY_HALF_POINT_HANDICAP_EXC_OT: 'THREE_WAY_HALF_POINT_HANDICAP_EXC_OT',
  THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT: 'THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT',
  HALF_POINT_OVER_UNDER_EXC_OT: 'HALF_POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT',
  HALF_RESULT_EXC_OT: 'HALF_RESULT_EXC_OT',
  TWO_WAY_HALF_RESULT_EXC_OT: 'TWO_WAY_HALF_RESULT_EXC_OT',
  THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT',
  TWO_WAY_PERIOD_RESULT_EXC_OT: 'TWO_WAY_PERIOD_RESULT_EXC_OT',
  THREE_WAY_TEAM_HALF_POINT_OVER_UNDER: 'THREE_WAY_TEAM_HALF_POINT_OVER_UNDER',
  TEAM_HALF_TOUCHDOWN_OVER_UNDER: 'TEAM_HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER',
  TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: 'TEAM_PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TOUCHDOWN_OVER_UNDER',
  TEAM_TOUCHDOWN_OVER_UNDER: 'TEAM_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER',
  PERIOD_TOUCHDOWN_OVER_UNDER: 'PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER',
  HALF_TOUCHDOWN_OVER_UNDER: 'HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER',
  HALF_TOUCHDOWN_ODD_EVEN: 'HALF_TOUCHDOWN_ODD_EVEN',
  HALF_TOUCHDOWN_RESULT: 'HALF_TOUCHDOWN_RESULT',
  THREE_WAY_HALF_TOUCHDOWN_RESULT: 'THREE_WAY_HALF_TOUCHDOWN_RESULT',
  PERIOD_TOUCHDOWN_ODD_EVEN: 'PERIOD_TOUCHDOWN_ODD_EVEN',
  PERIOD_TOUCHDOWN_RESULT: 'PERIOD_TOUCHDOWN_RESULT',
  THREE_WAY_TOUCHDOWN_RESULT: 'THREE_WAY_TOUCHDOWN_RESULT',
  TWO_WAY_TOUCHDOWN_RESULT: 'TWO_WAY_TOUCHDOWN_RESULT',
  TOUCHDOWN_HIGHEST_SCORING_HALF: 'TOUCHDOWN_HIGHEST_SCORING_HALF',
  TOUCHDOWN_HIGHEST_SCORING_PERIOD: 'TOUCHDOWN_HIGHEST_SCORING_PERIOD',
  TOUCHDOWN_ODD_EVEN: 'TOUCHDOWN_ODD_EVEN',
  FIELD_GOAL_RESULT_2_WAY: 'FIELD_GOAL_RESULT_2_WAY',
  FIELD_GOAL_RESULT_3_WAY: 'FIELD_GOAL_RESULT_3_WAY',

  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER: 'BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER',
  POINTS_IN_EACH_QUARTER: 'POINTS_IN_EACH_QUARTER',
  FIRST_SCORING_PLAY_TYPE: 'FIRST_SCORING_PLAY_TYPE',
  TEAM_TO_LEAD_AFTER_EACH_QUARTER: 'TEAM_TO_LEAD_AFTER_EACH_QUARTER',
  TEAM_TO_SCORE_X_OR_MORE_POINTS: 'TEAM_TO_SCORE_X_OR_MORE_POINTS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS',
  PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN: 'PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN',
  PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN: 'PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN',
  LOWEST_SCORING_HALF: 'LOWEST_SCORING_HALF',
  LOWEST_SCORING_PERIOD: 'LOWEST_SCORING_PERIOD',
  BOTH_TEAMS_TO_SCORE_FIELD_GOAL: 'BOTH_TEAMS_TO_SCORE_FIELD_GOAL',
  BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS: 'BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS',
  FIELD_GOAL_IN_EACH_PERIOD: 'FIELD_GOAL_IN_EACH_PERIOD',
  TOUCHDOWN_IN_EACH_PERIOD: 'TOUCHDOWN_IN_EACH_PERIOD',
  FIRST_SECOND_HALF_SCORING_PLAY_TYPE: 'FIRST_SECOND_HALF_SCORING_PLAY_TYPE',
  TEAM_FIRST_SCORING_PLAY_TYPE: 'TEAM_FIRST_SCORING_PLAY_TYPE',
  FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER: 'FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER',
  FIRST_SCORE_YARDAGE_OVER_UNDER: 'FIRST_SCORE_YARDAGE_OVER_UNDER',
  FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER: 'FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER',
  TEAM_NEXT_DRIVE_OUTCOME: 'TEAM_NEXT_DRIVE_OUTCOME',
  TEAM_TO_SCORE_FIRST_FIELD_GOAL: 'TEAM_TO_SCORE_FIRST_FIELD_GOAL',
  TEAM_TO_SCORE_FIRST_TOUCHDOWN: 'TEAM_TO_SCORE_FIRST_TOUCHDOWN',
  BOTH_TEAMS_TO_SCORE_IN_X_PERIOD: 'BOTH_TEAMS_TO_SCORE_IN_X_PERIOD',
  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF: 'BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS',
  LARGEST_LEAD_OF_GAME: 'LARGEST_LEAD_OF_GAME',
  LAST_SCORING_PLAY_TYPE: 'LAST_SCORING_PLAY_TYPE',
  HALF_TEAM_LAST_TO_SCORE: 'HALF_TEAM_LAST_TO_SCORE',
  LONGEST_TOUCHDOWN_SCORED: 'LONGEST_TOUCHDOWN_SCORED',
  PERIOD_OF_FIRST_FIELD_GOAL: 'PERIOD_OF_FIRST_FIELD_GOAL',
  PERIOD_OF_FIRST_TOUCHDOWN: 'PERIOD_OF_FIRST_TOUCHDOWN',
  TOUCHDOWN_IN_PERIOD: 'TOUCHDOWN_IN_PERIOD',
  TEAM_NUMBER_OF_HALVES_WON: 'TEAM_NUMBER_OF_HALVES_WON',
  TEAM_NUMBER_OF_PERIODS_WON: 'TEAM_NUMBER_OF_PERIODS_WON',
  TEAM_TO_SCORE_LONGEST_FIELD_GOAL: 'TEAM_TO_SCORE_LONGEST_FIELD_GOAL',
  TEAM_TO_SCORE_LONGEST_TOUCHDOWN: 'TEAM_TO_SCORE_LONGEST_TOUCHDOWN',
  POINT_TOTAL_BANDS: 'POINT_TOTAL_BANDS',
  NEXT_PLAY_OFFENSIVE_TOUCHDOWN: 'NEXT_PLAY_OFFENSIVE_TOUCHDOWN',
  POINT_MARGIN_BANDS: 'POINT_MARGIN_BANDS',
  FIELD_GOAL_RESULT: 'FIELD_GOAL_RESULT',
  TEAM_HALF_POINT_OVER_UNDER: 'TEAM_HALF_POINT_OVER_UNDER',
  TEAM_PERIOD_POINT_OVER_UNDER: 'TEAM_PERIOD_POINT_OVER_UNDER',
  TOUCHDOWN_RESULT: 'TOUCHDOWN_RESULT',
  RESULT_AND_OVER_UNDER: 'RESULT_AND_OVER_UNDER',
  TEAM_TO_SCORE_FIRST_LOSES_MATCH: 'TEAM_TO_SCORE_FIRST_LOSES_MATCH',

  // Micro markets added 15.09.2023.
  CURRENT_DRIVE_OUTCOME: 'CURRENT_DRIVE_OUTCOME',
  CURRENT_DRIVE_OUTCOME_GROUPED: 'CURRENT_DRIVE_OUTCOME_GROUPED',
  NEXT_DRIVE_OUTCOME: 'NEXT_DRIVE_OUTCOME',
  NEXT_DRIVE_OUTCOME_GROUPED: 'NEXT_DRIVE_OUTCOME_GROUPED',
  CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: 'CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT',
  NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: 'NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT',
  SITUATIONAL_EXTRA_POINT_CONVERSION_MADE: 'SITUATIONAL_EXTRA_POINT_CONVERSION_MADE',
  SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE: 'SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE',
  SITUATIONAL_TWO_POINTS_CONVERSION_MADE: 'SITUATIONAL_TWO_POINTS_CONVERSION_MADE',

  // not micro markets but added 15.09.2023. (discovered on supplement mode)
  PUNT_TOTAL_YARDS_OVER_UNDER: 'PUNT_TOTAL_YARDS_OVER_UNDER',
  NEXT_PLAY_IS_FIRST_DOWN: 'NEXT_PLAY_IS_FIRST_DOWN',
  PERIOD_TEAM_TO_SCORE_FIRST: 'PERIOD_TEAM_TO_SCORE_FIRST',
  SCORE_IN_LAST_TWO_MINUTES_OF_HALF: 'SCORE_IN_LAST_TWO_MINUTES_OF_HALF',
  BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE: 'BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE',

  MOST_POINTS_IN_PERIOD: 'MOST_POINTS_IN_PERIOD',
};

const MARKET_TYPES = {
  RESULT_2_WAY: 'RESULT_2_WAY',
  RESULT_3_WAY: 'RESULT_3_WAY',
  GOAL_HANDICAP_2_WAY: 'GOAL_HANDICAP_2_WAY',
  GOAL_HANDICAP_3_WAY: 'GOAL_HANDICAP_3_WAY',
  GOAL_OVER_UNDER_2_WAY: 'GOAL_OVER_UNDER_2_WAY',
  GOAL_OVER_UNDER_3_WAY: 'GOAL_OVER_UNDER_3_WAY',
  HALF_RESULT_2_WAY: 'HALF_RESULT_2_WAY',
  HALF_RESULT_3_WAY: 'HALF_RESULT_3_WAY',
  HALF_TIME_FULL_TIME_EXC_OT_4_WAY: 'HALF_TIME_FULL_TIME_EXC_OT_4_WAY',
  HALF_TIME_FULL_TIME_EXC_OT_6_WAY: 'HALF_TIME_FULL_TIME_EXC_OT_6_WAY',
  HALF_TIME_FULL_TIME_EXC_OT_9_WAY: 'HALF_TIME_FULL_TIME_EXC_OT_9_WAY',
  HALF_TIME_FULL_TIME_4_WAY: 'HALF_TIME_FULL_TIME_4_WAY',
  HALF_TIME_FULL_TIME_6_WAY: 'HALF_TIME_FULL_TIME_6_WAY',
  POINT_HANDICAP_INC_OT_2_WAY: 'POINT_HANDICAP_INC_OT_2_WAY',
  POINT_HANDICAP_INC_OT_3_WAY: 'POINT_HANDICAP_INC_OT_3_WAY',
  HALF_POINT_HANDICAP_2_WAY: 'HALF_POINT_HANDICAP_2_WAY',
  HALF_POINT_HANDICAP_3_WAY: 'HALF_POINT_HANDICAP_3_WAY',
  PERIOD_POINT_HANDICAP_2_WAY: 'PERIOD_POINT_HANDICAP_2_WAY',
  PERIOD_POINT_HANDICAP_3_WAY: 'PERIOD_POINT_HANDICAP_3_WAY',
  POINT_HANDICAP_2_WAY: 'POINT_HANDICAP_2_WAY',
  POINT_HANDICAP_3_WAY: 'POINT_HANDICAP_3_WAY',
  HALF_POINT_OVER_UNDER_2_WAY: 'HALF_POINT_OVER_UNDER_2_WAY',
  HALF_POINT_OVER_UNDER_3_WAY: 'HALF_POINT_OVER_UNDER_3_WAY',
  POINT_OVER_UNDER_INC_OT_2_WAY: 'POINT_OVER_UNDER_INC_OT_2_WAY',
  POINT_OVER_UNDER_INC_OT_3_WAY: 'POINT_OVER_UNDER_INC_OT_3_WAY',
  TEAM_POINT_OVER_UNDER_INC_OT_2_WAY: 'TEAM_POINT_OVER_UNDER_INC_OT_2_WAY',
  TEAM_POINT_OVER_UNDER_INC_OT_3_WAY: 'TEAM_POINT_OVER_UNDER_INC_OT_3_WAY',
  NEXT_POINT_2_WAY: 'NEXT_POINT_2_WAY',
  NEXT_POINT_3_WAY: 'NEXT_POINT_3_WAY',
  PERIOD_POINT_OVER_UNDER_2_WAY: 'PERIOD_POINT_OVER_UNDER_2_WAY',
  PERIOD_POINT_OVER_UNDER_3_WAY: 'PERIOD_POINT_OVER_UNDER_3_WAY',
  PERIOD_GOAL_OVER_UNDER_2_WAY: 'PERIOD_GOAL_OVER_UNDER_2_WAY',
  PERIOD_GOAL_OVER_UNDER_3_WAY: 'PERIOD_GOAL_OVER_UNDER_3_WAY',
  PERIOD_GOAL_HANDICAP_2_WAY: 'PERIOD_GOAL_HANDICAP_2_WAY',
  PERIOD_GOAL_HANDICAP_3_WAY: 'PERIOD_GOAL_HANDICAP_3_WAY',
  PERIOD_RESULT_2_WAY: 'PERIOD_RESULT_2_WAY',
  PERIOD_RESULT_3_WAY: 'PERIOD_RESULT_3_WAY',
  POINT_OVER_UNDER_2_WAY: 'POINT_OVER_UNDER_2_WAY',
  POINT_OVER_UNDER_3_WAY: 'POINT_OVER_UNDER_3_WAY',
  TEAM_HALF_POINT_OVER_UNDER_2_WAY: 'TEAM_HALF_POINT_OVER_UNDER_2_WAY',
  TEAM_HALF_POINT_OVER_UNDER_3_WAY: 'TEAM_HALF_POINT_OVER_UNDER_3_WAY',
  TEAM_PERIOD_POINT_OVER_UNDER_2_WAY: 'TEAM_PERIOD_POINT_OVER_UNDER_2_WAY',
  TEAM_PERIOD_POINT_OVER_UNDER_3_WAY: 'TEAM_PERIOD_POINT_OVER_UNDER_3_WAY',
  TEAM_PERIOD_GOAL_OVER_UNDER_2_WAY: 'TEAM_PERIOD_GOAL_OVER_UNDER_2_WAY',
  TEAM_PERIOD_GOAL_OVER_UNDER_3_WAY: 'TEAM_PERIOD_GOAL_OVER_UNDER_3_WAY',
  TEAM_POINT_OVER_UNDER_2_WAY: 'TEAM_POINT_OVER_UNDER_2_WAY',
  TEAM_POINT_OVER_UNDER_3_WAY: 'TEAM_POINT_OVER_UNDER_3_WAY',
  TEAM_GOAL_OVER_UNDER_2_WAY: 'TEAM_GOAL_OVER_UNDER_2_WAY',
  TEAM_GOAL_OVER_UNDER_3_WAY: 'TEAM_GOAL_OVER_UNDER_3_WAY',
  RESULT_EXC_OT_2_WAY: 'RESULT_EXC_OT_2_WAY',
  RESULT_EXC_OT_3_WAY: 'RESULT_EXC_OT_3_WAY',
  MATCH_RESULT_AND_RUNS_OVER_UNDER_4_WAY: 'MATCH_RESULT_AND_RUNS_OVER_UNDER_4_WAY',
  MATCH_RESULT_AND_RUNS_OVER_UNDER_6_WAY: 'MATCH_RESULT_AND_RUNS_OVER_UNDER_6_WAY',
  TOUCHDOWN_RESULT_2_WAY: 'TOUCHDOWN_RESULT_2_WAY',
  TOUCHDOWN_RESULT_3_WAY: 'TOUCHDOWN_RESULT_3_WAY',
};

const MARKET_MAPPINGS = {
  // Soccer
  [MARKET_TYPES.RESULT_2_WAY]: 'Match Winner Money Line',
  [MARKET_TYPES.RESULT_3_WAY]: 'Match Winner',
  [MARKET_TYPES.GOAL_OVER_UNDER_2_WAY]: 'Total',
  [MARKET_TYPES.GOAL_OVER_UNDER_3_WAY]: 'Total',
  [MARKET_TYPES.HALF_RESULT_2_WAY]: 'Half Winner Money Line',

  // Basketball
  [EXCLUSION_MARKET_TYPES.POINT_RACE_TO_X]: 'Race to',
  [EXCLUSION_MARKET_TYPES.HALF_POINT_RACE_TO_X]: 'Half Race to',
  [EXCLUSION_MARKET_TYPES.PERIOD_POINT_RACE_TO_X]: 'Period Race to',
  [MARKET_TYPES.POINT_HANDICAP_INC_OT_2_WAY]: 'Point Spread (incl. Overtime) 2Way',
  [MARKET_TYPES.POINT_HANDICAP_INC_OT_3_WAY]: 'Point Spread (incl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.POINT_MARGIN]: 'Winning Margin',
  [EXCLUSION_MARKET_TYPES.HALF_POINT_ODD_EVEN]: 'Half Odd / Even',
  [EXCLUSION_MARKET_TYPES.POINT_ODD_EVEN_INC_OT]: 'Total Odd / Even (incl. Overtime)',
  [MARKET_TYPES.POINT_OVER_UNDER_INC_OT_2_WAY]: 'Total Over / Under (incl. Overtime) 2Way',
  [MARKET_TYPES.POINT_OVER_UNDER_INC_OT_3_WAY]: 'Total Over / Under (incl. Overtime) 3Way',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER_INC_OT_2_WAY]: 'Team Total Over / Under 2Way',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER_INC_OT_3_WAY]: 'Team Total Over / Under 3Way',
  [EXCLUSION_MARKET_TYPES.HIGHEST_SCORING_HALF]: 'Highest Scoring Half',
  [EXCLUSION_MARKET_TYPES.HIGHEST_SCORING_PERIOD]: 'Highest Scoring Period',
  [MARKET_TYPES.NEXT_POINT_2_WAY]: 'Next Point 2Way',
  [MARKET_TYPES.NEXT_POINT_3_WAY]: 'Next Point 3Way',
  [EXCLUSION_MARKET_TYPES.PERIOD_POINT_ODD_EVEN]: 'Period Odd / Even Total',
  [MARKET_TYPES.PERIOD_RESULT_2_WAY]: 'Period Moneyline',
  [EXCLUSION_MARKET_TYPES.POINT_ODD_EVEN]: 'Odd / Even Total',
  [EXCLUSION_MARKET_TYPES.TEAM_HALF_POINT_ODD_EVEN]: 'Half Odd / Even Team Total',
  [MARKET_TYPES.TEAM_HALF_POINT_OVER_UNDER_2_WAY]: 'Half Over / Under Team Total 2Way',
  [MARKET_TYPES.TEAM_HALF_POINT_OVER_UNDER_3_WAY]: 'Half Over / Under Team Total 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_POINT_ODD_EVEN]: 'Period Odd / Even Team Total',
  [MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER_2_WAY]: 'Period Over / Under Team Total 2Way',
  [MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER_3_WAY]: 'Period Over / Under Team Total 3Way',
  [MARKET_TYPES.TEAM_PERIOD_GOAL_OVER_UNDER_2_WAY]: 'Period Over / Under Team Total',
  [MARKET_TYPES.TEAM_PERIOD_GOAL_OVER_UNDER_3_WAY]: 'Period Over / Under Team Total',
  [EXCLUSION_MARKET_TYPES.TEAM_POINT_ODD_EVEN]: 'Team Odd / Even Total',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER_2_WAY]: 'Team Over / Under Total 2Way',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER_3_WAY]: 'Team Over / Under Total 3Way',
  [MARKET_TYPES.TEAM_GOAL_OVER_UNDER_2_WAY]: 'Team Total',
  [MARKET_TYPES.TEAM_GOAL_OVER_UNDER_3_WAY]: 'Team Total',
  [EXCLUSION_MARKET_TYPES.TO_GO_TO_OVERTIME]: 'Will there be Overtime?',
  [MARKET_TYPES.RESULT_EXC_OT_2_WAY]: 'Match Winner (excl. OT) Money Line',

  // Ultimate
  [EXCLUSION_MARKET_TYPES.POINT_ASIAN_HANDICAP]: 'Asian Handicap',
  [MARKET_TYPES.HALF_POINT_HANDICAP_2_WAY]: 'Half Point Spread 2Way',
  [MARKET_TYPES.HALF_POINT_HANDICAP_3_WAY]: 'Half Point Spread 3Way',
  [MARKET_TYPES.POINT_HANDICAP_2_WAY]: 'Point Spread 2Way',
  [MARKET_TYPES.POINT_HANDICAP_3_WAY]: 'Point Spread 3Way',
  [MARKET_TYPES.GOAL_HANDICAP_2_WAY]: 'Spread',
  [MARKET_TYPES.GOAL_HANDICAP_3_WAY]: 'Spread',
  [MARKET_TYPES.HALF_POINT_OVER_UNDER_2_WAY]: 'Half Over / Under 2Way',
  [MARKET_TYPES.HALF_POINT_OVER_UNDER_3_WAY]: 'Half Over / Under 3Way',
  [MARKET_TYPES.PERIOD_POINT_OVER_UNDER_2_WAY]: 'Period Over / Under Total 2Way',
  [MARKET_TYPES.PERIOD_POINT_OVER_UNDER_3_WAY]: 'Period Over / Under Total 3Way',
  [MARKET_TYPES.PERIOD_POINT_HANDICAP_2_WAY]: 'Period Point Spread 2Way',
  [MARKET_TYPES.PERIOD_POINT_HANDICAP_3_WAY]: 'Period Point Spread 3Way',
  [MARKET_TYPES.PERIOD_GOAL_OVER_UNDER_2_WAY]: 'Period Total',
  [MARKET_TYPES.PERIOD_GOAL_OVER_UNDER_3_WAY]: 'Period Total',
  [MARKET_TYPES.PERIOD_GOAL_HANDICAP_2_WAY]: 'Period Spread',
  [MARKET_TYPES.PERIOD_GOAL_HANDICAP_3_WAY]: 'Period Spread',
  [MARKET_TYPES.POINT_OVER_UNDER_2_WAY]: 'Over / Under Total 2Way',
  [MARKET_TYPES.POINT_OVER_UNDER_3_WAY]: 'Over / Under Total 3Way',
  [MARKET_TYPES.PERIOD_RESULT_3_WAY]: 'Period Winner 3Way',
  [MARKET_TYPES.HALF_RESULT_3_WAY]: 'Half Winner 3Way',
  [MARKET_TYPES.RESULT_EXC_OT_3_WAY]: 'Match Winner (excl. OT) 3Way',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_EXC_OT_4_WAY]: 'Half Time / Full Time (excl. Overtime) 4Way',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_EXC_OT_6_WAY]: 'Half Time / Full Time (excl. Overtime) 6Way',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_EXC_OT_9_WAY]: 'Half Time / Full Time (excl. Overtime) 9Way',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_4_WAY]: 'Half Time / Full Time 4Way',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_6_WAY]: 'Half Time / Full Time 6Way',

  // Baseball

  [MARKET_TYPES.MATCH_RESULT_AND_RUNS_OVER_UNDER_4_WAY]: 'Moneyline and Total',
  [MARKET_TYPES.MATCH_RESULT_AND_RUNS_OVER_UNDER_6_WAY]: 'Match Betting and Total',

  // Player props
  [EXCLUSION_MARKET_TYPES.PLAYER_LAST_TOUCHDOWN_SCORER]: 'Player last touchdown scorer',
  [EXCLUSION_MARKET_TYPES.PLAYER_ANYTIME_TOUCHDOWN_SCORER]: 'Player any touchdown scorer',
  [EXCLUSION_MARKET_TYPES.PLAYER_NEXT_TOUCHDOWN_SCORER]: 'Player next touchdown scorer',
  [EXCLUSION_MARKET_TYPES.PLAYER_PASSING_ATTEMPTS_OVER_UNDER]: 'Player passing attempts',
  [EXCLUSION_MARKET_TYPES.PLAYER_PASSING_COMPLETIONS_OVER_UNDER]: 'Player passing completions',
  [EXCLUSION_MARKET_TYPES.PLAYER_PASSING_YARDS_OVER_UNDER]: 'Player passing yards',
  [EXCLUSION_MARKET_TYPES.PLAYER_RECEIVING_YARDS_OVER_UNDER]: 'Player receiving yards',
  [EXCLUSION_MARKET_TYPES.PLAYER_RECEPTIONS_OVER_UNDER]: 'Player receptions',
  [EXCLUSION_MARKET_TYPES.PLAYER_RUSHING_YARDS_OVER_UNDER]: 'Player rushing yards',
  [EXCLUSION_MARKET_TYPES.PLAYER_TOUCHDOWNS_OVER_UNDER]: 'Player touchdowns',

  [EXCLUSION_MARKET_TYPES.HALF_CORRECT_SCORE]: 'Correct score - Half',
  [EXCLUSION_MARKET_TYPES.HANDICAP_AND_OVER_UNDER]: 'Same game parlay team to under',
  [EXCLUSION_MARKET_TYPES.MATCH_RESULT_AND_POINTS_OVER_UNDER]: 'Same game parlay moneyline to over',
  [EXCLUSION_MARKET_TYPES.NEXT_BASKET_EXACT]: 'Next field goal exact',
  [EXCLUSION_MARKET_TYPES.NEXT_BASKET_TYPE]: 'Next field goal type',
  [EXCLUSION_MARKET_TYPES.NEXT_TWO_POINTER]: 'Team to score next 2 pointer',
  [EXCLUSION_MARKET_TYPES.NEXT_THREE_POINTER]: 'Team to score next 3 pointer',
  [EXCLUSION_MARKET_TYPES.PLAYER_POINT_OVER_UNDER]: 'Player points',
  [EXCLUSION_MARKET_TYPES.PLAYER_POINT_BANDS]: 'Player point bands',
  [EXCLUSION_MARKET_TYPES.PLAYER_REBOUND_OVER_UNDER]: 'Player rebounds',
  [EXCLUSION_MARKET_TYPES.PLAYER_ASSIST_OVER_UNDER]: 'Player assists',
  [EXCLUSION_MARKET_TYPES.PLAYER_THREE_POINTER_OVER_UNDER]: 'Player threes',
  [EXCLUSION_MARKET_TYPES.ANYTIME_GOALSCORER]: 'Anytime scorer',
  [EXCLUSION_MARKET_TYPES.ANY_QUARTER_TO_BE_SCORELESS]: 'Will any quarter be scoreless?',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE]: 'Both teams to score',
  [EXCLUSION_MARKET_TYPES.CORRECT_SCORE]: 'Correct score - full game',
  [EXCLUSION_MARKET_TYPES.CORRECT_SCORE_EXC_OT]: 'Correct score (excl. OT)',
  [EXCLUSION_MARKET_TYPES.DEFENSIVE_OR_SPECIAL_TOUCHDOWN]: 'Will there be a defensive or special teams TD?',
  [EXCLUSION_MARKET_TYPES.DRIVE_CROSSES_YARD_LINE]: 'Drive crosses the 50 yard line',
  [EXCLUSION_MARKET_TYPES.DRIVE_OUTCOME]: 'Drive result exact',
  [EXCLUSION_MARKET_TYPES.DRIVE_OUTCOME_GROUPED]: 'Drive result grouped',
  [EXCLUSION_MARKET_TYPES.EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY]: 'Will either team gain a 1st down on a 4th down play (includes fake kicks and penalties)?',
  [EXCLUSION_MARKET_TYPES.EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW]: 'Will either team record 4 consecutive scores (safeties count)?',
  [EXCLUSION_MARKET_TYPES.EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW]: 'Will either team record 3 consecutive scores (safeties count)?',
  [EXCLUSION_MARKET_TYPES.EITHER_TEAM_WIN_BY_THREE_OR_SEVEN]: 'Will either team win the game by exactly 3 or 7 points?',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_OVER_UNDER]: 'How many total field goals will be scored?',
  [EXCLUSION_MARKET_TYPES.FIRST_DOWN_OVER_UNDER]: 'How many total first downs will be gained?',
  [EXCLUSION_MARKET_TYPES.FIRST_DOWN_RESULT]: 'Which team will achieve the most first downs?',
  [EXCLUSION_MARKET_TYPES.FIRST_PITCHER_TOTAL_STRIKEOUTS]: 'Away first pitcher total strikeouts',
  [EXCLUSION_MARKET_TYPES.FIRST_SCORE_IS_A_TOUCHDOWN]: 'Will first score be a touchdown?',
  [EXCLUSION_MARKET_TYPES.FOURTH_QUARTER_LEAD_CHANGE]: 'Will there be a 4th quarter lead change in the game (tie is not a lead)?',
  [EXCLUSION_MARKET_TYPES.GOAL_IN_TIME_BIN]: 'Will there be a goal in the next 5 mins?',
  [EXCLUSION_MARKET_TYPES.GOAL_MARGIN]: 'Winning margin (incl. OT)',
  [EXCLUSION_MARKET_TYPES.GOAL_MARGIN_EXC_OT]: 'Winning margin (excl. OT)',
  [EXCLUSION_MARKET_TYPES.GOAL_ODD_EVEN_EXC_OT]: 'Total points odd/even (excl. OT)',
  [EXCLUSION_MARKET_TYPES.GOAL_RACE_TO_X]: 'Race to goals',
  [EXCLUSION_MARKET_TYPES.HALF_TIME_FULL_TIME_TIES_LOSE]: 'Half Time / Full Time (Ties Lose)',
  [EXCLUSION_MARKET_TYPES.LAST_GOAL]: 'Last to score',
  [EXCLUSION_MARKET_TYPES.LAST_GOALSCORER]: 'Last scorer',
  [EXCLUSION_MARKET_TYPES.LAST_SCORE_IS_A_TOUCHDOWN]: 'Will last score be a touchdown?',
  [EXCLUSION_MARKET_TYPES.LONGEST_DRIVE_YARDS_OVER_UNDER]: 'How many yards will the longest drive distance be?',
  [EXCLUSION_MARKET_TYPES.LONGEST_FIELD_GOAL_YARDS_OVER_UNDER]: 'How many yards will the longest made field goal distance be?',
  [EXCLUSION_MARKET_TYPES.NEXT_CONVERSION]: 'Situational conversion outcome',
  [EXCLUSION_MARKET_TYPES.NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL]: 'Will the next field goal attempt be successful?',
  [EXCLUSION_MARKET_TYPES.NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL]: 'Will the next field goal attempt be successful?',
  [EXCLUSION_MARKET_TYPES.NEXT_FIELD_GOAL_MADE]: 'Situational field goal make',
  [EXCLUSION_MARKET_TYPES.NEXT_GOAL]: 'Next goal scored by',
  [EXCLUSION_MARKET_TYPES.NEXT_GOALSCORER]: 'Next player to score',
  [EXCLUSION_MARKET_TYPES.NEXT_KICKOFF_TOUCHBACK]: 'Situational kickoff touchback',
  [EXCLUSION_MARKET_TYPES.NEXT_PLAY_IS_A_FIRST_DOWN]: 'Will the next play be a first down?',
  [EXCLUSION_MARKET_TYPES.NEXT_PLAY_TOUCHDOWN]: 'Next play to result in a touchdown',
  [EXCLUSION_MARKET_TYPES.NEXT_PLAY_TYPE]: 'Next play attempt type',
  [EXCLUSION_MARKET_TYPES.NEXT_POSSESSION_OUTCOME]: 'Next Possession result two-play',
  [EXCLUSION_MARKET_TYPES.NEXT_POSSESSION_OUTCOME_EXACT]: 'Next Possession result exact',
  [EXCLUSION_MARKET_TYPES.NEXT_PUNT_FAIR_CATCH]: 'Situational punt return fair catch',
  [EXCLUSION_MARKET_TYPES.PERIOD_CORRECT_SCORE]: 'Period Correct score',
  [EXCLUSION_MARKET_TYPES.PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES]: 'Total goals under X minutes',
  [EXCLUSION_MARKET_TYPES.PERIOD_PLAYER_POINT_BANDS]: 'Period Player Point Bands',
  [EXCLUSION_MARKET_TYPES.PERIOD_PLAYER_POINT_OVER_UNDER]: 'Period Player Points',
  [EXCLUSION_MARKET_TYPES.PERIOD_PLAYER_THREE_POINTER_OVER_UNDER]: 'Period Player Threes',
  [EXCLUSION_MARKET_TYPES.PERIOD_POINT_HANDICAP_EXC_OT]: 'Side Handicap of X Period',
  [EXCLUSION_MARKET_TYPES.PERIOD_POINT_OVER_UNDER_EXC_OT]: 'Total of X Period',
  [EXCLUSION_MARKET_TYPES.PERIOD_RESULT_EXC_OT]: 'Moneyline of X Period',
  [EXCLUSION_MARKET_TYPES.PERIOD_RESULT_FULL_TIME]: 'Period / Full Time',
  [EXCLUSION_MARKET_TYPES.PERIOD_RUN_HANDICAP]: 'Inning Spread',
  [EXCLUSION_MARKET_TYPES.PERIOD_RUN_OVER_UNDER]: 'Inning Total',
  [EXCLUSION_MARKET_TYPES.PITCH_OUTCOME]: 'Pitch result',
  [EXCLUSION_MARKET_TYPES.PITCH_SPEED_BANDED]: 'Pitch speed banded',
  [EXCLUSION_MARKET_TYPES.PITCH_SPEED_OVER_UNDER]: 'Pitch speed over/under',
  [EXCLUSION_MARKET_TYPES.PLATE_APPEARANCE_PITCHES_OVER_UNDER]: 'Plate appearance pitch count',
  [EXCLUSION_MARKET_TYPES.PLATE_APPEARANCE_RESULT_EXACT]: 'Plate appearance result exact',
  [EXCLUSION_MARKET_TYPES.PLATE_APPEARANCE_RESULT_GROUPED]: 'Plate appearance result grouped',
  [EXCLUSION_MARKET_TYPES.PLATE_APPEARANCE_RESULT_ON_BASE]: 'Plate appearance result on base',
  [EXCLUSION_MARKET_TYPES.PLAYER_BASES_OVER_UNDER]: 'Player total bases',
  [EXCLUSION_MARKET_TYPES.PLAYER_HOME_RUN]: 'Player 1+ HR',
  [EXCLUSION_MARKET_TYPES.PLAYER_ON_BASE]: 'Player gets on base',
  [EXCLUSION_MARKET_TYPES.PLAYER_ON_BASE_TWO_PLUS]: 'Player gets on base 2+',
  [EXCLUSION_MARKET_TYPES.PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER]: 'Player passing touchdowns over/under',
  [EXCLUSION_MARKET_TYPES.PLAYER_RBI]: 'Player 1+ RBI',
  [EXCLUSION_MARKET_TYPES.PLAYER_RUN_OVER_UNDER]: 'Player total runs',
  [EXCLUSION_MARKET_TYPES.PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER]: 'Player rushing yards + passing over/under',
  [EXCLUSION_MARKET_TYPES.PLAYER_SHOTS_OVER_UNDER]: 'Player shots on goal',
  [EXCLUSION_MARKET_TYPES.PLAYER_STOLEN_BASE]: 'Player 1+ SB',
  [EXCLUSION_MARKET_TYPES.PLAYER_STRIKEOUT_OVER_UNDER]: 'Pitcher total strikeouts',
  [EXCLUSION_MARKET_TYPES.PLAYER_TOUCHDOWN_X_OR_MORE]: 'Player will score at least one touchdown',
  [EXCLUSION_MARKET_TYPES.PUNT_OVER_UNDER]: 'How many total punts will be attempted (counts when ball hits foot)?',
  [EXCLUSION_MARKET_TYPES.PUNT_YARDS_OVER_UNDER]: 'How many net yards will the next punt gain?',
  [EXCLUSION_MARKET_TYPES.RESULT_AFTER]: 'To Be Winning After Inning',
  [EXCLUSION_MARKET_TYPES.TWO_WAY_RESULT_AFTER]: 'To Be Winning After Inning 2Way',
  [EXCLUSION_MARKET_TYPES.RUN_HANDICAP]: 'Spread',
  [EXCLUSION_MARKET_TYPES.RUN_HANDICAP_AFTER]: 'Spread After Inning',
  [EXCLUSION_MARKET_TYPES.RUN_MARGIN]: 'Winning Margin',
  [EXCLUSION_MARKET_TYPES.RUN_OVER_UNDER]: 'Total',
  [EXCLUSION_MARKET_TYPES.RUN_OVER_UNDER_AFTER]: 'Total After Inning',
  [EXCLUSION_MARKET_TYPES.SAFETY_SCORED]: 'Will either team score a safety?',
  [EXCLUSION_MARKET_TYPES.SECOND_HALF_LEAD_CHANGE]: 'Will there be a 2nd half lead change in the game (tie is not lead)?',
  [EXCLUSION_MARKET_TYPES.STRIKEOUT_OVER_UNDER]: 'Total strikeouts',
  [EXCLUSION_MARKET_TYPES.SUCCESSFUL_TWO_POINT_CONVERSION]: 'Will either team record a successful 2pt conversion (includes fakes and accidents)?',
  [EXCLUSION_MARKET_TYPES.TEAM_FIRST_TO_SCORE]: 'Which team will score first?',
  [EXCLUSION_MARKET_TYPES.TEAM_LAST_TO_SCORE]: 'Which team will score last?',
  [EXCLUSION_MARKET_TYPES.TEAM_NEXT_BASKET_TYPE]: 'Team next field goal type',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_NEXT_BASKET]: 'Team to score next basket',
  [EXCLUSION_MARKET_TYPES.TEAM_NEXT_TO_SCORE]: 'Game score next',
  [EXCLUSION_MARKET_TYPES.TEAM_OVER_UNDER]: 'Team total of full game',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_BALLS_IN_PLAY]: 'Half inning number of balls in play exact',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER]: 'Half inning number of balls in play over/under',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_BATTERS_TO_PLATE]: 'Half inning number of batters to the plate exact',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER]: 'Half inning number of batters to the plate over/under',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_HITS]: 'Half inning number of hits exact',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_HITS_OVER_UNDER]: 'Half inning number of hits over/under',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_HOME_RUN]: 'Half inning team run',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_PITCHES_BANDED]: 'Half inning number of pitches thrown banded',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_PITCHES_OVER_UNDER]: 'Half inning number of pitches thrown over/under',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT]: 'Team total of X period',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_RUN_OVER_UNDER]: 'Team Inning Total',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_RUN_SCORED]: 'Half inning run scored',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_STRIKEOUT_OVER_UNDER]: 'Period inning number of strikeouts over/under',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_THREE_BATTERS_REACH_BASE]: '3-batter reach base',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_THREE_BATTERS_STRIKEOUT]: 'Period inning strikeout the side',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_TO_SCORE]: 'Will team score points in X period?',
  [EXCLUSION_MARKET_TYPES.TEAM_RUN_OVER_UNDER]: 'Team Total',
  [EXCLUSION_MARKET_TYPES.TEAM_RUN_OVER_UNDER_AFTER]: 'Team total of X inning',
  [EXCLUSION_MARKET_TYPES.TEAM_SCORE_IN_EACH_PERIOD]: 'Will team score points in all quarters?',
  [EXCLUSION_MARKET_TYPES.TEAM_STACK_ATTACK_OVER_UNDER]: 'Stack attack X team',
  [EXCLUSION_MARKET_TYPES.TEAM_STRIKEOUT_OVER_UNDER]: 'Total team strikeouts',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_FIRST_WINS_MATCH]: 'Will the team that scores first win the game?',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_IN_OWN_DRIVE]: 'Game drive',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_LAST_WINS_MATCH]: 'Will the team that scores last win the game?',
  [EXCLUSION_MARKET_TYPES.TIE_AFTER_NIL_NIL]: 'Will there be a tie in the score after 0-0?',
  [EXCLUSION_MARKET_TYPES.TIME_OF_FIRST_SCORE]: 'How much game time will have elapsed at the time of the 1st score?',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_IN_TIME_BIN]: 'Will there be a touchdown during 5 minute bucket?',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_OVER_UNDER]: 'How many total touchdowns will be scored?',
  [EXCLUSION_MARKET_TYPES.TO_GO_THREE_AND_OUT]: 'Next to go three and out',
  [EXCLUSION_MARKET_TYPES.TURNOVER_OVER_UNDER]: 'How many total turnover (interceptions and fumbles will be committed)',
  [EXCLUSION_MARKET_TYPES.WIN_TO_NIL]: 'Team to win with shutout',
  [EXCLUSION_MARKET_TYPES.LAST_POINT]: 'Last point',
  [EXCLUSION_MARKET_TYPES.FIRST_SCORER_WINS_MATCH]: 'First scorer wins match',
  [EXCLUSION_MARKET_TYPES.MOST_POINTS_IN_SECOND_QUARTER]: 'Most Points In Second Quarter',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_ODD_EVEN]: 'Field Goals Odd / Even',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_OVER_UNDER]: 'Total Field Goals',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_FIELD_GOAL_OVER_UNDER]: 'Total Field Goals 3Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_FIELD_GOAL_RESULT]: 'Field Goals Winner 3Way',
  [EXCLUSION_MARKET_TYPES.TWO_WAY_FIELD_GOAL_RESULT]: 'Field Goals Winner 2Way',
  [EXCLUSION_MARKET_TYPES.HALF_FIELD_GOAL_ODD_EVEN]: 'Half Field Goals Odd / Even',
  [EXCLUSION_MARKET_TYPES.HALF_FIELD_GOAL_OVER_UNDER]: 'Half Total Field Goals',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER]: 'Half Total Field Goals 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_FIELD_GOAL_RESULT]: 'Half Field Goals Winner',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_FIELD_GOAL_RESULT]: 'Half Field Goals Winner 3Way',
  [EXCLUSION_MARKET_TYPES.PERIOD_FIELD_GOAL_ODD_EVEN]: 'Quarter Field Goals Odd / Even',
  [EXCLUSION_MARKET_TYPES.PERIOD_FIELD_GOAL_OVER_UNDER]: 'Quarter Total Field Goals',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER]: 'Quarter Total Field Goals 3Way',
  [EXCLUSION_MARKET_TYPES.PERIOD_FIELD_GOAL_RESULT]: 'Quarter Field Goals Winner',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_PERIOD_FIELD_GOAL_RESULT]: 'Quarter Field Goals Winner 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_HALF_FIELD_GOAL_OVER_UNDER]: 'Half Total Field Goals',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER]: 'Half Total Field Goals 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_FIELD_GOAL_OVER_UNDER]: 'Quarter Total Field Goals',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER]: 'Quarter Total Field Goals 3Way',
  [EXCLUSION_MARKET_TYPES.POINT_HANDICAP_EXC_OT]: 'Point Spread (Excl. Overtime)',
  [EXCLUSION_MARKET_TYPES.POINT_OVER_UNDER_EXC_OT]: 'Total Points (Excl. Overtime)',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_POINT_OVER_UNDER_EXC_OT]: 'Total Points (Excl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_POINT_OVER_UNDER]: 'Team Total Points 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_POINT_HANDICAP_EXC_OT]: 'Half Point Spread (Excl. Overtime) 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_POINT_HANDICAP_EXC_OT]: 'Half Point Spread (Excl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT]: 'Quarter Point Spread (Excl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_POINT_OVER_UNDER_EXC_OT]: 'Half Total Points (Excl. Overtime) 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT]: 'Half Total Points (Excl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_RESULT_EXC_OT]: 'Half Winner (Excl. Overtime)',
  [EXCLUSION_MARKET_TYPES.TWO_WAY_HALF_RESULT_EXC_OT]: 'Half Winner (Excl. Overtime) 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT]: 'Quarter Total Points (Excl. Overtime) 3Way',
  [EXCLUSION_MARKET_TYPES.TWO_WAY_PERIOD_RESULT_EXC_OT]: 'Quarter Winner (Excl. Overtime) 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_HALF_POINT_OVER_UNDER]: 'Half Team Total Points 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_HALF_TOUCHDOWN_OVER_UNDER]: 'Half Team Total Touchdowns',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER]: 'Half Team Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER]: 'Quarter Team Total Points 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_TOUCHDOWN_OVER_UNDER]: 'Quarter Team Total Touchdowns 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER]: 'Quarter Team Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TOUCHDOWN_OVER_UNDER]: 'Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.TEAM_TOUCHDOWN_OVER_UNDER]: 'Team Total Touchdowns 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER]: 'Team Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.PERIOD_TOUCHDOWN_OVER_UNDER]: 'Quarter Total Touchdowns 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER]: 'Quarter Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_TOUCHDOWN_OVER_UNDER]: 'Half Total Touchdowns 2Way',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER]: 'Half Total Touchdowns 3Way',
  [EXCLUSION_MARKET_TYPES.HALF_TOUCHDOWN_ODD_EVEN]: 'Half Touchdowns Odd / Even',
  [EXCLUSION_MARKET_TYPES.HALF_TOUCHDOWN_RESULT]: 'Half Touchdowns Winner',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_HALF_TOUCHDOWN_RESULT]: 'Half Touchdowns Winner 3Way',
  [EXCLUSION_MARKET_TYPES.PERIOD_TOUCHDOWN_ODD_EVEN]: 'Quarter Touchdowns Odd / Even',
  [EXCLUSION_MARKET_TYPES.PERIOD_TOUCHDOWN_RESULT]: 'Quarter Touchdowns Winner',
  [EXCLUSION_MARKET_TYPES.THREE_WAY_TOUCHDOWN_RESULT]: 'Touchdowns Winner 3Way',
  [EXCLUSION_MARKET_TYPES.TWO_WAY_TOUCHDOWN_RESULT]: 'Touchdowns Winner 2Way',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_HIGHEST_SCORING_HALF]: 'Highest Touchdown Scoring Half',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_HIGHEST_SCORING_PERIOD]: 'Highest Touchdown Scoring Quarter',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_ODD_EVEN]: 'Touchdown Odd / Even',
  [MARKET_TYPES.TOUCHDOWN_RESULT_2_WAY]: 'Touchdown Winner 2Way',
  [MARKET_TYPES.TOUCHDOWN_RESULT_3_WAY]: 'Touchdown Winner 3Way',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_RESULT_2_WAY]: 'Field Goals Winner 2Way',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_RESULT_3_WAY]: 'Field Goals Winner 3Way',

  // new markets 11.8.2023. NCAAF
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER]: 'Both Teams To Score In Each Quarter',
  [EXCLUSION_MARKET_TYPES.POINTS_IN_EACH_QUARTER]: 'Points In Each Quarter',
  [EXCLUSION_MARKET_TYPES.FIRST_SCORING_PLAY_TYPE]: 'First Scoring Type',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_LEAD_AFTER_EACH_QUARTER]: 'Team To Lead After Each Quarter',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_X_OR_MORE_POINTS]: 'Team To Score X',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS]: 'Both Teams To Score X',
  [EXCLUSION_MARKET_TYPES.PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN]: 'Player To Score 1st TD & Team To Win',
  [EXCLUSION_MARKET_TYPES.PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN]: 'Player To Score A TD & Team To Win',
  [EXCLUSION_MARKET_TYPES.LOWEST_SCORING_HALF]: 'Lowest Scoring Half',
  [EXCLUSION_MARKET_TYPES.LOWEST_SCORING_PERIOD]: 'Lowest Scoring Quarter',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_FIELD_GOAL]: 'Both Teams To Score a Field Goal',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS]: 'Both Teams To Score X+ Touchdowns & Y+ Field Goals',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_IN_EACH_PERIOD]: 'Field Goal Scored In Every Quarter',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_IN_EACH_PERIOD]: 'Touchdown Scored In Every Quarter',
  [EXCLUSION_MARKET_TYPES.FIRST_SECOND_HALF_SCORING_PLAY_TYPE]: '2nd Half First Scoring Type',
  [EXCLUSION_MARKET_TYPES.TEAM_FIRST_SCORING_PLAY_TYPE]: 'Team First Scoring Type',
  [EXCLUSION_MARKET_TYPES.FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER]: 'First Field Goal Yardage',
  [EXCLUSION_MARKET_TYPES.FIRST_SCORE_YARDAGE_OVER_UNDER]: 'First Score Yardage',
  [EXCLUSION_MARKET_TYPES.FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER]: 'First Touchdown Yardage',
  [EXCLUSION_MARKET_TYPES.TEAM_NEXT_DRIVE_OUTCOME]: 'Team Outcome Of First Drive', // team, drive_index
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_FIRST_FIELD_GOAL]: 'First Team To Score A Field Goal',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_FIRST_TOUCHDOWN]: 'First Team To Score A Touchdown',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_IN_X_PERIOD]: 'Quarter - Both Teams To Score In',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF]: 'Both Teams To Score In Both Halves',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS]: 'Both Teams To Score X Field Goals',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS]: 'Both Teams To Score X Touchdowns',
  [EXCLUSION_MARKET_TYPES.LARGEST_LEAD_OF_GAME]: 'Largest Lead',
  [EXCLUSION_MARKET_TYPES.LAST_SCORING_PLAY_TYPE]: 'Last Scoring Play',
  [EXCLUSION_MARKET_TYPES.HALF_TEAM_LAST_TO_SCORE]: 'Last Team To Score In 1st Half',
  [EXCLUSION_MARKET_TYPES.LONGEST_TOUCHDOWN_SCORED]: 'Longest Touchdown Yardage',
  [EXCLUSION_MARKET_TYPES.PERIOD_OF_FIRST_FIELD_GOAL]: 'Quarter of First Field Goal',
  [EXCLUSION_MARKET_TYPES.PERIOD_OF_FIRST_TOUCHDOWN]: 'Quarter of First Touchdown',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_IN_PERIOD]: 'Quarter Touchdown Scored',
  [EXCLUSION_MARKET_TYPES.TEAM_NUMBER_OF_HALVES_WON]: 'Team Number Of Halves Won',
  [EXCLUSION_MARKET_TYPES.TEAM_NUMBER_OF_PERIODS_WON]: 'Team Number Of Quarters Won',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_LONGEST_FIELD_GOAL]: 'Team To Score The Longest Field Goal',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_LONGEST_TOUCHDOWN]: 'Team To Score The Longest Touchdown',
  [EXCLUSION_MARKET_TYPES.POINT_TOTAL_BANDS]: 'Total Bands',
  [EXCLUSION_MARKET_TYPES.NEXT_PLAY_OFFENSIVE_TOUCHDOWN]: 'Touchdown Scored On Drive X Play Y', // play, drive_index
  [EXCLUSION_MARKET_TYPES.POINT_MARGIN_BANDS]: 'Winning Margin',
  [EXCLUSION_MARKET_TYPES.FIELD_GOAL_RESULT]: 'Most Field Goals',
  [EXCLUSION_MARKET_TYPES.TEAM_HALF_POINT_OVER_UNDER]: 'Half Team Total',
  [EXCLUSION_MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER]: 'Quarter Team Total',
  [EXCLUSION_MARKET_TYPES.TOUCHDOWN_RESULT]: 'Most Touchdowns',
  [EXCLUSION_MARKET_TYPES.RESULT_AND_OVER_UNDER]: 'Moneyline and Total',
  [EXCLUSION_MARKET_TYPES.TEAM_TO_SCORE_FIRST_LOSES_MATCH]: 'Team To Score First To Lose The Match',

  // Micro markets added 15.09.2023.
  [EXCLUSION_MARKET_TYPES.CURRENT_DRIVE_OUTCOME]: 'Current Drive Outcome',
  [EXCLUSION_MARKET_TYPES.CURRENT_DRIVE_OUTCOME_GROUPED]: 'Grouped Current Drive Outcome',
  [EXCLUSION_MARKET_TYPES.NEXT_DRIVE_OUTCOME]: 'Outcome Of Next Drive',
  [EXCLUSION_MARKET_TYPES.NEXT_DRIVE_OUTCOME_GROUPED]: 'Grouped Outcome Of Next Drive',
  [EXCLUSION_MARKET_TYPES.CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT]: 'Team To Go Three And Out in Current Drive',
  [EXCLUSION_MARKET_TYPES.NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT]: 'Team To Go Three And Out in Next Drive',
  [EXCLUSION_MARKET_TYPES.SITUATIONAL_EXTRA_POINT_CONVERSION_MADE]: 'Extra Point Conversion To Be Made After Score',
  [EXCLUSION_MARKET_TYPES.SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE]: 'Field Goal To Be Made On The X Play Of The Drive',
  [EXCLUSION_MARKET_TYPES.SITUATIONAL_TWO_POINTS_CONVERSION_MADE]: 'Two Point Conversion To Be Made After Score',

  [EXCLUSION_MARKET_TYPES.PUNT_TOTAL_YARDS_OVER_UNDER]: 'Punt Total Yards',
  [EXCLUSION_MARKET_TYPES.NEXT_PLAY_IS_FIRST_DOWN]: 'Next Play in X Drive is 1st down',
  [EXCLUSION_MARKET_TYPES.PERIOD_TEAM_TO_SCORE_FIRST]: 'Period Team To Score First',
  [EXCLUSION_MARKET_TYPES.SCORE_IN_LAST_TWO_MINUTES_OF_HALF]: 'Score In Last 2 Minutes Of Half',
  [EXCLUSION_MARKET_TYPES.BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE]: 'Both Teams X Or More Touchdowns and X Or More Field Goals On Same Line',

  [EXCLUSION_MARKET_TYPES.MOST_POINTS_IN_PERIOD]: 'Most Points in Quarter',
};

const SELECTION_SORT_MAPPINGS = {
  HOME: 100,
  HOME_USA: 130,
  OVER: 110,
  DRAW: 120,
  AWAY: 130,
  AWAY_USA: 100,
  UNDER: 140,
  EVEN: 200,
  ODD: 210,
  YES: 220,
  NO: 230,
  HOME_HOME: 400,
  DRAW_DRAW: 401,
  DRAW_HOME: 402,
  AWAY_AWAY: 403,
  DRAW_AWAY: 404,
  HOME_DRAW: 405,
  AWAY_DRAW: 406,
  AWAY_HOME: 407,
  HOME_AWAY: 408,
  FIELD_GOAL: 351,
  NO_FIELD_GOAL: 352,
  OPPOSITION_TOUCHDOWN: 353,
  ANY_DRAW: 354,
  ANY_TURNOVER: 355,
  ANY_OTHER: 356,
  TWO_POINTER: 356,
  THREE_POINTER: 359,
  HOME_TWO_POINTER: 357,
  HOME_THREE_POINTER: 358,
  AWAY_TWO_POINTER: 359,
  AWAY_THREE_POINTER: 360,
  AWAY_TWO_POINTER_USA: 375,
  AWAY_THREE_POINTER_USA: 376,
  HOME_TWO_POINTER_USA: 377,
  HOME_THREE_POINTER_USA: 378,
  FREE_THROW: 362,
  NO_SCORE: 363,
  CORRECT_SCORE_HOME: 365,
  CORRECT_SCORE_AWAY: 367,
  CORRECT_SCORE_AWAY_USA: 368,
  CORRECT_SCORE_HOME_USA: 369,
};

const teamFromIndex = (index) => (!index ? 'Home' : 'Away');

const mapNumberSuffix = (num) => {
  let numberSuffix = '';
  switch (num.slice(-1)) {
  case '1':
    numberSuffix = 'st';
    break;
  case '2':
    numberSuffix = 'nd';
    break;
  case '3':
    numberSuffix = 'rd';
    break;
  default:
    numberSuffix = 'th';
    break;
  }
  switch (num.slice(-2)) {
  case '11':
  case '12':
  case '13':
    numberSuffix = 'th';
    break;
  default:
    break;
  }
  return numberSuffix;
};

const getNumberWithSuffix = (num) => `${num}${mapNumberSuffix(num)}`;

const SELECTION_MAPPINGS = {
  HOME: () => 'Home',
  DRAW: () => 'Draw',
  AWAY: () => 'Away',
  OVER: () => 'Over',
  UNDER: () => 'Under',
  ODD: () => 'Odd',
  EVEN: () => 'Even',
  NEITHER: () => 'Neither',
  ANY_DRAW: () => 'Any Draw',
  FIELD_GOAL: () => 'Field Goal',
  NO_FIELD_GOAL: () => 'No Field Goal',
  OPPOSITION_TOUCHDOWN: () => 'Opposition Touchdown',
  ANY_OTHER: () => 'Any Other',
  ANY_TURNOVER: () => 'Any Turnover',
  YES: () => 'Yes',
  NO: () => 'No',
  HOME_HOME: () => 'Home/Home',
  AWAY_AWAY: () => 'Away/Away',
  DRAW_DRAW: () => 'Draw/Draw',
  HOME_AWAY: () => 'Home/Away',
  AWAY_HOME: () => 'Away/Home',
  HOME_DRAW: () => 'Home/Draw',
  DRAW_HOME: () => 'Draw/Home',
  AWAY_DRAW: () => 'Away/Draw',
  DRAW_AWAY: () => 'Draw/Away',
  HALF: (selection) => `${getNumberWithSuffix(selection.selectionType.params.HALF_X?.toString())} Half`,
  PERIOD: (selection) => `${getNumberWithSuffix(selection.selectionType.params.PERIOD_X?.toString())} Period`,
  MARGIN: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.LOWER_BOUND}-${selection.selectionType.params.UPPER_BOUND}`,
  MARGIN_AND_ABOVE: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.LOWER_BOUND}+`,
  MARGIN_EXACT: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.MARGIN}`,
  BAND: (selection) => `${selection.selectionType.params.LOWER_BOUND}-${selection.selectionType.params.UPPER_BOUND}`,
  BAND_AND_ABOVE: (selection) => `${selection.selectionType.params.LOWER_BOUND}+`,
  CORRECT_SCORE: (selection, isUsaView) => (isUsaView
    ? `${selection.selectionType.params.CORRECT_SCORE_AWAY}-${selection.selectionType.params.CORRECT_SCORE_HOME}`
    : `${selection.selectionType.params.CORRECT_SCORE_HOME}-${selection.selectionType.params.CORRECT_SCORE_AWAY}`),
  TWO_POINTER: () => '2 Points',
  HOME_TWO_POINTER: () => 'Home 2 Points',
  AWAY_TWO_POINTER: () => 'Away 2 Points',
  THREE_POINTER: () => '3 Points',
  HOME_THREE_POINTER: () => 'Home 3 Points',
  AWAY_THREE_POINTER: () => 'Away 3 Points',
  FREE_THROW: () => 'Free Throws',
  NO_SCORE: () => 'No Basket',
  IN_PLAY: () => 'Ball In Play',
  DOUBLE_OR_TRIPLE: () => 'Double or Triple',
  HOME_RUN: () => 'Home Run',
  IN_PLAY_OUT: () => 'Out',
  EXTRA_BASE_HIT: () => 'Extra Base Hit',
  SINGLE_OR_WALK: () => 'Single or Walk',
  GOALS: (selection) => `${selection.selectionType.params.GOALS_X} ${selection.selectionType.params.GOALS_X === 1 ? 'Goal' : 'Goals'}`,
};

export const marketName = (marketCode, sport) => {
  if (!marketCode) return undefined;

  let name = MARKET_MAPPINGS[marketCode] || marketCode;

  if (sport?.label !== 'ICE_HOCKEY' && sport?.label !== 'BASEBALL') {
    name = name.replace('Period', 'Quarter');
  }

  if (sport?.label === 'BASEBALL') {
    name = name.replace('Period', 'Inning');
  }

  return name;
};

const RESULTING_STATUS_TYPES = {
  RESULTED: 'RESULTED',
  PENDING_RESULTING: 'PENDING_RESULTING',
  RESULT_AMENDED: 'RESULT_AMENDED',
  NOT_RESULTED: 'NOT_RESULTED',
  SUSPENDED: 'SUSPENDED', // this one is not received from the backend
};

const marketNameExtension = (marketParams) => marketParams.LINE || marketParams.PERIOD || '';

const selectionName = (selection, sport, isUsaView) => {
  const selectionCode = selection?.selectionType?.selectionCode || 'N/A';
  let name = SELECTION_MAPPINGS[selectionCode] ? SELECTION_MAPPINGS[selectionCode](selection, isUsaView) : selectionCode;

  const sportLabel = sport?.sportLabel || sport?.label || '';

  if (sportLabel !== 'ICE_HOCKEY' && sportLabel !== 'BASEBALL') {
    name = name.replace('Period', 'Quarter').replace('PERIOD', 'QUARTER');
  }

  if (sportLabel === 'BASEBALL') {
    name = name.replace('Period', 'Inning').replace('PERIOD', 'INNING');
  }

  return name;
};

const countMarkets = (markets, { marketCode }) => countBy(markets, (market) => marketCode && marketCode === market.marketCode && market.displayStatus === 'ON').true;

const openMarkets = (markets) => filter(
  markets,
  (market) => market.displayStatus === 'ON'
    && find(
      market.selections,
      ({ price: { decimalValue }, displayStatus }) => decimalValue && displayStatus === 'ON',
    ),
);

const sortMarkets = (markets) => orderBy(markets, ['marketType.params.PERIOD', 'marketType.params.HALF', 'marketType.params.LINE', 'source']);

const sortSectionMarkets = (markets, isUsaView) => {
  const teamOrder = isUsaView ? 'desc' : 'asc';
  return orderBy(markets,
    ['marketType.params.PERIOD', 'marketType.params.HALF', 'marketType.params.TEAM', 'marketType.params.PLAYER_INDEX',
      'marketType.params.RESULT_AFTER_X', 'marketType.params.RACE_TO', 'marketSummary.isMainLine', 'marketType.params.LINE', 'marketType.params.SCORE_INDEX',
      'marketType.params.DRIVE_INDEX', 'marketType.params.BIN_MINUTE_START', 'marketType.params.PUNT', 'marketType.params.PLAY',
      'marketType.params.FIELD_GOAL', 'marketType.params.POSSESSION', 'marketType.params.YARD_LINE', 'marketType.params.PITCH_INDEX',
      'marketType.params.PLATE_APPEARANCE', 'marketType.params.BATTER_PLAYER_INDEX', 'marketType.params.PITCHER_PLAYER_INDEX', 'marketType.params.IS_TOP_INNING',
      'marketType.params.TEAM_SCORE_AT_CREATION', 'marketType.params.HOME_SCORE_AT_CREATION', 'marketType.params.AWAY_SCORE_AT_CREATION', 'marketType.params.MILESTONE_YARDS', 'marketType.params.MILESTONE'],
    ['asc', 'asc', teamOrder, 'asc',
      'asc', 'asc', 'desc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc', 'asc']);
};

const sortSelections = ({ selections, isUSAView }) => {
  if (!selections || !isArray(selections)) return undefined;
  if (find(selections, (selection) => selection?.selectionType?.params?.HALF_X)) {
    return orderBy(selections, 'selectionType.params.HALF_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.PERIOD_X)) {
    return orderBy(selections, 'selectionType.params.PERIOD_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.GOALS_X)) {
    return orderBy(selections, 'selectionType.params.GOALS_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.LOWER_BOUND)) {
    const teamOrder = isUSAView ? 'desc' : 'asc';
    return orderBy(selections, ['selectionType.params.TEAM', 'selectionType.params.MARGIN', 'selectionType.params.LOWER_BOUND'], [teamOrder, 'asc', 'asc']);
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.CORRECT_SCORE_HOME)) {
    return !isUSAView
      ? orderBy(selections, ['selectionType.params.CORRECT_SCORE_HOME', 'selectionType.params.CORRECT_SCORE_AWAY'])
      : orderBy(selections, ['selectionType.params.CORRECT_SCORE_AWAY', 'selectionType.params.CORRECT_SCORE_HOME']);
  }
  return sortBy(selections, (selection) => {
    if (isUSAView && includes(['HOME', 'AWAY', 'HOME_TWO_POINTER', 'HOME_THREE_POINTER', 'AWAY_TWO_POINTER', 'AWAY_THREE_POINTER', 'CORRECT_SCORE_HOME', 'CORRECT_SCORE_AWAY'],
    selection?.selectionType?.selectionCode)) {
      return SELECTION_SORT_MAPPINGS[`${selection?.selectionType?.selectionCode}_USA`];
    }
    return SELECTION_SORT_MAPPINGS[selection?.selectionType?.selectionCode];
  });
};

const isContainingResultingStatus = (resultingStatusArr, status) => resultingStatusArr.includes(status);

const getResultingStatus = (data) => {
  let isResulted = false;
  let isPendingResulting = false;
  let isResultAmended = false;
  let isNotResulted = false;

  const { resultingStatusArr } = data;
  let resultingStatus = {};
  if (data.eventSuspended || data.marketTypeSuspended) {
    resultingStatus = { type: RESULTING_STATUS_TYPES.SUSPENDED, label: 'Suspended' };
    return resultingStatus;
  }

  if (isContainingResultingStatus(resultingStatusArr, RESULTING_STATUS_TYPES.RESULTED)) {
    isResulted = true;
  }
  if (isContainingResultingStatus(resultingStatusArr, RESULTING_STATUS_TYPES.PENDING_RESULTING)) {
    isPendingResulting = true;
  }
  if (isContainingResultingStatus(resultingStatusArr, RESULTING_STATUS_TYPES.RESULT_AMENDED)) {
    isResultAmended = true;
  }
  if (isContainingResultingStatus(resultingStatusArr, RESULTING_STATUS_TYPES.NOT_RESULTED)) {
    isNotResulted = true;
  }

  if (
    isResulted
    && !isPendingResulting
    && !isResultAmended
    && !isNotResulted
  ) {
    resultingStatus = { type: RESULTING_STATUS_TYPES.RESULTED, label: 'Resulted' };
  }

  if (
    isResulted
    && isPendingResulting
    && !isResultAmended
    && !isNotResulted
  ) {
    resultingStatus = {
      type: RESULTING_STATUS_TYPES.PENDING_RESULTING,
      label: 'Pending resulting',
      icon: 'loading',
    };
  }

  if (isResultAmended) {
    resultingStatus = {
      type: RESULTING_STATUS_TYPES.RESULT_AMENDED,
      /* label: 'Incorect resulting', */
      label: '',
      /* icon: 'alert-cycle', */
    };
  }

  resultingStatus = {
    type: RESULTING_STATUS_TYPES.NOT_RESULTED,
    label: 'Active',
    showUnsuspendBtn: true,
  };

  return resultingStatus;
};

const mapUngroupedMarket = ({
  market, marketDisplayConfiguration, homeTeam, awayTeam,
}) => {
  const startingBracketIndexes = [];
  const endingBracketIndexes = [];

  const { longNamePattern } = marketDisplayConfiguration;
  for (let i = 0; i < longNamePattern?.length; i += 1) {
    if (longNamePattern[i] === '{') startingBracketIndexes.push(i);
    if (longNamePattern[i] === '}') endingBracketIndexes.push(i);
  }

  const paramsToReplace = [];

  for (let i = 0; i < startingBracketIndexes.length; i += 1) {
    paramsToReplace.push(longNamePattern.slice(startingBracketIndexes[i] + 1, endingBracketIndexes[i]));
  }
  let displayName = longNamePattern;
  if (!paramsToReplace.length) {
    return {
      ...market,
      displayName,
    };
  }
  each(paramsToReplace, (param) => {
    if (param === 'TEAM') {
      const team = market.marketType.params.TEAM === 0 ? homeTeam : awayTeam;
      const teamName = includes(paramsToReplace, 'PLAYER_INDEX') ? team.shortName : team.name;
      displayName = displayName.replace('TEAM', teamName);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PLAYER_INDEX') {
      const playerdisplayNameSplit = market.marketSummary?.playerName?.split(', ');
      const playerDisplayName = playerdisplayNameSplit
        ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
        : '';
      displayName = displayName.replace(param, playerDisplayName || market.marketSummary?.playerName || 'N/A');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PITCHER_PLAYER_INDEX' || param === 'BATTER_PLAYER_INDEX') {
      const playerIndex = market.marketType.params[param];
      const playerName = market.marketSummary?.players?.[playerIndex]?.playerName;
      const playerdisplayNameSplit = playerName?.split(', ');
      const playerDisplayName = playerdisplayNameSplit
        ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
        : '';
      displayName = displayName.replace(param, playerDisplayName || market.marketSummary?.playerName || 'N/A');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'IS_TOP_INNING') {
      const isTopInningValue = market.marketType.params.IS_TOP_INNING === 0 ? 'Bottom' : 'Top';
      displayName = displayName.replace('IS_TOP_INNING', isTopInningValue);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    const valueToReplace = market.marketType.params[param];
    const numberSuffix = marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL' ? mapNumberSuffix(valueToReplace.toString()) : '';
    displayName = displayName.replace(param, `${valueToReplace}${numberSuffix}`);
    displayName = displayName.replace(/{|}/g, '');
  });
  return {
    ...market,
    displayName,
  };
};

const mapBetTickerMarketName = ({
  marketParams, marketSummary, marketDisplayConfiguration, homeTeam, awayTeam,
}) => {
  const startingBracketIndexes = [];
  const endingBracketIndexes = [];

  const { longNamePattern } = marketDisplayConfiguration;
  for (let i = 0; i < longNamePattern?.length; i += 1) {
    if (longNamePattern[i] === '{') startingBracketIndexes.push(i);
    if (longNamePattern[i] === '}') endingBracketIndexes.push(i);
  }

  const paramsToReplace = [];

  for (let i = 0; i < startingBracketIndexes.length; i += 1) {
    paramsToReplace.push(longNamePattern.slice(startingBracketIndexes[i] + 1, endingBracketIndexes[i]));
  }
  let displayName = longNamePattern;
  if (!paramsToReplace.length) {
    return displayName;
  }
  each(paramsToReplace, (param) => {
    if (param === 'TEAM') {
      const team = marketParams.TEAM === 0 ? homeTeam : awayTeam;
      const teamName = includes(paramsToReplace, 'PLAYER_INDEX') ? team.shortName : team.name;
      displayName = displayName.replace('TEAM', teamName);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PLAYER_INDEX') {
      const playerdisplayNameSplit = marketSummary?.playerName?.split(', ');
      const playerDisplayName = playerdisplayNameSplit
        ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
        : '';
      displayName = displayName.replace(param, playerDisplayName || marketSummary?.playerName || 'N/A');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'PITCHER_PLAYER_INDEX' || param === 'BATTER_PLAYER_INDEX') {
      const playerIndex = marketParams[param];
      const playerName = marketSummary?.players?.[playerIndex]?.playerName;
      const playerdisplayNameSplit = playerName?.split(', ');
      const playerDisplayName = playerdisplayNameSplit
        ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
        : '';
      displayName = displayName.replace(param, playerDisplayName || marketSummary?.playerName || 'N/A');
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    if (param === 'IS_TOP_INNING') {
      const isTopInningValue = marketParams.IS_TOP_INNING === 0 ? 'Bottom' : 'Top';
      displayName = displayName.replace('IS_TOP_INNING', isTopInningValue);
      displayName = displayName.replace(/{|}/g, '');
      return;
    }
    const valueToReplace = marketParams[param];
    const numberSuffix = marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL' ? mapNumberSuffix(valueToReplace.toString()) : '';
    displayName = displayName.replace(param, `${valueToReplace}${numberSuffix}`);
    displayName = displayName.replace(/{|}/g, '');
  });
  return displayName;
};

const PLAYERS_MARKET_MAPPINGS = {
  PLAYER_POINT_OVER_UNDER: 'Points',
  PLAYER_REBOUND_OVER_UNDER: 'Rebounds',
  PLAYER_ASSIST_OVER_UNDER: 'Assists',
  PLAYER_THREE_POINTER_OVER_UNDER: 'Threes',
  PLAYER_POINT_BANDS: 'Point bands',
  ANYTIME_TOUCHDOWN_SCORER: 'Any TD',
  NEXT_TOUCHDOWN_SCORER: 'Next TD',
  LAST_TOUCHDOWN_SCORER: 'Last TD',
  PLAYER_PASSING_YARDS_OVER_UNDER: 'Passing yards',
  PLAYER_PASSING_ATTEMPTS_OVER_UNDER: 'Passing attempts',
  PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER: 'Passing TD',
  PLAYER_RECEIVING_YARDS_OVER_UNDER: 'Receiving yards',
  PLAYER_RUSHING_YARDS_OVER_UNDER: 'Rushing yards',
  PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER: 'Pass+rush yards',
  PLAYER_TOUCHDOWN_X_OR_MORE: 'X or more TD',
  PLAYER_RECEPTIONS_OVER_UNDER: 'Receptions',
  PLAYER_NEXT_TOUCHDOWN_SCORER: 'Next TD scorer',
  PLAYER_ANYTIME_TOUCHDOWN_SCORER: 'Anytime TD scorer',
  PLAYER_LAST_TOUCHDOWN_SCORER: 'Last TD scorer',
  PLAYER_GOAL_OVER_UNDER: 'Goals',
  PLAYER_ANYTIME_GOALSCORER: 'Anytime Goalscorer',
  PLAYER_LAST_GOALSCORER: 'Last Goalscorer',
  PLAYER_FIRST_GOALSCORER: 'First Goalscorer',
  PLAYER_NEXT_GOALSCORER: 'Next Goalscorer',
  PLAYER_ON_BASE: 'On Base',
  PLAYER_STOLEN_BASE: 'Stolen Base',
  PLAYER_BASES_OVER_UNDER: 'Bases',
  PLAYER_HOME_RUN: 'Home Run',
  PLAYER_ON_BASE_TWO_PLUS: 'On Base Twice or More',
  PLAYER_RBI: 'Runs Batted In',
  PLAYER_RUN_OVER_UNDER: 'Runs',
  PLAYER_STRIKEOUT_OVER_UNDER: 'Strikeouts',
  PLAYER_TOUCHDOWN_THREE_OR_MORE: 'Touchdowns Scored 3+',
  PLAYER_FIRST_TOUCHDOWN_SCORER: 'First Touchdown Scorer',
  PLAYER_PASSING_YARDS_MILESTONE: 'Passing Yards Milestone',
  PLAYER_RECEIVING_TOUCHDOWNS_OVER_UNDER: 'Receiving Touchdowns',
  PLAYER_RECEIVING_YARDS_MILESTONE: 'Receiving Yards Milestone',
  PLAYER_RUSHING_TOUCHDOWNS_OVER_UNDER: 'Rushing Touchdowns',
  PLAYER_RUSHING_YARDS_MILESTONE: 'Rushing Yards Milestone',
  PLAYER_TOUCHDOWN_TWO_OR_MORE: 'Touchdowns Scored 2+',
  PLAYER_LONGEST_RUSH_YARDS: 'Longest Rush Yards',
  PLAYER_LONGEST_COMPLETION_YARDS: 'Longest Completion Yards',
  PLAYER_FIELD_GOAL_OVER_UNDER: 'Field Goals',
  PLAYER_FIELD_GOAL_ATTEMPT_OVER_UNDER: 'Field Goal Attempts',
  PLAYER_PASSING_COMPLETIONS_OVER_UNDER: 'Passing Completions',
  PLAYER_POINTS_MILESTONE: 'Points Milestone',
  PLAYER_REBOUNDS_MILESTONE: 'Rebounds Milestone',
  PLAYER_ASSISTS_MILESTONE: 'Assists Milestone',
  PLAYER_THREES_MILESTONE: 'Threes Milestone',
  PLAYER_PRA_MILESTONE: 'P+R+A Milestone',
  PLAYER_DOUBLE_DOUBLE: 'Double Double',
  PLAYER_TRIPLE_DOUBLE: 'Triple Double',
  PLAYER_POINTS_REBOUNDS_ASSISTS: 'P+R+A',
  PLAYER_POINTS_ASSISTS: 'Points + Assists',
  PLAYER_POINTS_REBOUNDS: 'Points + Rebounds',
  PLAYER_REBOUNDS_ASSISTS: 'Rebounds + Assists',
  PLAYER_EXTRA_POINTS_OVER_UNDER: 'Extra Points',
  PLAYER_INTERCEPTIONS_MADE_YES_NO: 'Interception Made',
  PLAYER_INTERCEPTIONS_THROWN_OVER_UNDER: 'Interceptions Thrown',
  PLAYER_KICKING_POINTS_OVER_UNDER: 'Kicking Points',
  PLAYER_RUSHING_ATTEMPTS_OVER_UNDER: 'Rushing Attempts',
  PLAYER_SACKS_YES_NO: 'Sack Made',
  PLAYER_TACKLES_OVER_UNDER: 'Tackles Made',
  PLAYER_BLOCKS_OVER_UNDER: 'Blocks',
  PLAYER_STEALS_OVER_UNDER: 'Steals',
};

const BASKETBALL_PLAYERS_MARKET_MAPPINGS_ORDER = {
  PLAYER_POINT_OVER_UNDER: 1,
  PLAYER_THREE_POINTER_OVER_UNDER: 2,
  PLAYER_REBOUND_OVER_UNDER: 3,
  PLAYER_ASSIST_OVER_UNDER: 4,
  PLAYER_POINT_BANDS: 5,
};

const FOOTBALL_PLAYERS_MARKET_MAPPINGS_ORDER = {
  ANYTIME_TOUCHDOWN_SCORER: 1,
  NEXT_TOUCHDOWN_SCORER: 2,
  LAST_TOUCHDOWN_SCORER: 3,
  PLAYER_PASSING_YARDS_OVER_UNDER: 4,
  PLAYER_PASSING_ATTEMPTS_OVER_UNDER: 5,
  PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER: 6,
  PLAYER_RECEIVING_YARDS_OVER_UNDER: 7,
  PLAYER_RUSHING_YARDS_OVER_UNDER: 8,
  PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER: 9,
  PLAYER_TOUCHDOWN_X_OR_MORE: 10,
  PLAYER_RECEPTIONS_OVER_UNDER: 11,
  PLAYER_NEXT_TOUCHDOWN_SCORER: 12,
  PLAYER_ANYTIME_TOUCHDOWN_SCORER: 13,
  PLAYER_LAST_TOUCHDOWN_SCORER: 14,
  PLAYER_EXTRA_POINTS_OVER_UNDER: 15,
  PLAYER_INTERCEPTIONS_MADE_YES_NO: 16,
  PLAYER_INTERCEPTIONS_THROWN_OVER_UNDER: 17,
  PLAYER_KICKING_POINTS_OVER_UNDER: 18,
  PLAYER_RUSHING_ATTEMPTS_OVER_UNDER: 19,
  PLAYER_SACKS_YES_NO: 20,
  PLAYER_TACKLES_OVER_UNDER: 21,
};

const HOCKEY_PLAYERS_MARKET_MAPPINGS_ORDER = {
  PLAYER_GOAL_OVER_UNDER: 1,
  PLAYER_ASSIST_OVER_UNDER: 2,
  PLAYER_POINT_OVER_UNDER: 3,
  PLAYER_FIRST_GOALSCORER: 4,
  PLAYER_LAST_GOALSCORER: 5,
  PLAYER_NEXT_GOALSCORER: 6,
  PLAYER_ANYTIME_GOALSCORER: 7,
};

const BASEBALL_PLAYERS_MARKET_MAPPINGS_ORDER = {
  PLAYER_HOME_RUN: 1,
  PLAYER_RUN_OVER_UNDER: 2,
  PLAYER_STRIKEOUT_OVER_UNDER: 3,
  PLAYER_RBI: 4,
  PLAYER_BASES_OVER_UNDER: 5,
  PLAYER_ON_BASE: 6,
  PLAYER_STOLEN_BASE: 7,
  PLAYER_ON_BASE_TWO_PLUS: 8,
};

const playersMarketName = (marketCode) => {
  if (!marketCode) return undefined;
  return PLAYERS_MARKET_MAPPINGS[marketCode] || marketCode;
};

const sortPlayerMarkets = (markets, sportId) => {
  let orderBySport;
  switch (sportId) {
  case FOOTBALL_ID:
    orderBySport = FOOTBALL_PLAYERS_MARKET_MAPPINGS_ORDER;
    break;
  case BASKETBALL_ID:
    orderBySport = BASKETBALL_PLAYERS_MARKET_MAPPINGS_ORDER;
    break;
  case HOCKEY_ID:
    orderBySport = HOCKEY_PLAYERS_MARKET_MAPPINGS_ORDER;
    break;
  case BASEBALL_ID:
    orderBySport = BASEBALL_PLAYERS_MARKET_MAPPINGS_ORDER;
    break;
  default:
    orderBySport = null;
  }
  if (!orderBySport) return markets;
  return sortBy(markets, (market) => orderBySport[market.marketCode]);
};

const formatMarketGroups = (marketGroups, marketNodes, sportLabel) => map(marketGroups, (marketGroup) => {
  if (marketGroup.code === 'ALL') { return marketGroup; }
  let { displayName } = marketGroup;
  const markets = map(marketGroup.marketCodes.nodes, (market) => market.marketCode);
  const matchingMarkets = filter(markets, (marketCode) => includes(map(marketNodes, (node) => node.marketCode), marketCode)).length;

  if (sportLabel !== 'ICE_HOCKEY' && displayName === 'Period') {
    displayName = 'Quarter';
  }

  return {
    ...marketGroup,
    displayName,
    matchingMarkets,
    markets,
  };
});

export default {
  HUDDLE_MARKET_SOURCE,
  marketName,
  marketNameExtension,
  selectionName,
  countMarkets,
  openMarkets,
  sortMarkets,
  sortSectionMarkets,
  sortSelections,
  getResultingStatus,
  RESULTING_STATUS_TYPES,
  playersMarketName,
  sortPlayerMarkets,
  formatMarketGroups,
  mapNumberSuffix,
  mapUngroupedMarket,
  mapBetTickerMarketName,
};
